/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
    ClientDto,
    ClientDtoFromJSON,
    ClientDtoToJSON,
    ClientType,
    ClientTypeFromJSON,
    ClientTypeToJSON,
} from '../models';

export interface ApiDcrV1ClientsClientNameBoundPutRequest {
    clientName: string;
    body?: string;
}

export interface ApiDcrV1ClientsGetRequest {
    id?: string;
    search?: string;
    groupId?: string;
    type?: ClientType;
}

export interface ApiDcrV1ClientsPostRequest {
    client?: Client;
}

/**
 * 
 */
export class ClientApi extends runtime.BaseAPI {

    /**
     */
    async apiDcrV1ClientsAzTypesGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/clients/az/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiDcrV1ClientsAzTypesGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<string>> {
        const response = await this.apiDcrV1ClientsAzTypesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1ClientsClientNameBoundPutRaw(requestParameters: ApiDcrV1ClientsClientNameBoundPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ClientDto>>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling apiDcrV1ClientsClientNameBoundPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/clients/{clientName}/bound`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientDtoFromJSON));
    }

    /**
     */
    async apiDcrV1ClientsClientNameBoundPut(requestParameters: ApiDcrV1ClientsClientNameBoundPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ClientDto>> {
        const response = await this.apiDcrV1ClientsClientNameBoundPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1ClientsGetRaw(requestParameters: ApiDcrV1ClientsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ClientDto>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['Search'] = requestParameters.search;
        }

        if (requestParameters.groupId !== undefined) {
            queryParameters['GroupId'] = requestParameters.groupId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/clients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientDtoFromJSON));
    }

    /**
     */
    async apiDcrV1ClientsGet(requestParameters: ApiDcrV1ClientsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ClientDto>> {
        const response = await this.apiDcrV1ClientsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1ClientsPostRaw(requestParameters: ApiDcrV1ClientsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ClientDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/clients`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientToJSON(requestParameters.client),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientDtoFromJSON));
    }

    /**
     */
    async apiDcrV1ClientsPost(requestParameters: ApiDcrV1ClientsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ClientDto>> {
        const response = await this.apiDcrV1ClientsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
