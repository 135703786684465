import {getObject, setObject} from '../../../../../core';
import {UserDto} from '../../../../../services/management';
import {UserTableStore} from "./UserTableStore";
import {UserRolesTabStore} from "./tabs/UserRolesTabStore";
import {UserParametersTabStore} from "./tabs/UserParametersTabStore";
import {DynamicalComponentStore} from "../../../../utils";
import {UserJurisdictionsTabStore} from "./tabs/UserJurisdictionsTabStore";
import {AllPermissions} from "../../../../../core/const";

interface UsersViewModelLocalFilters {
    tabIndex: number;
}

export class UserItemStore extends DynamicalComponentStore {
    readonly _store: UserTableStore;
    public dto: UserDto;
    parameters: UserParametersTabStore;
    roles: UserRolesTabStore;
    jurisdictions: UserJurisdictionsTabStore;

    constructor(store: UserTableStore, dto: UserDto) {
        super();
        this._store = store;
        this.dto = dto;
        this.parameters = new UserParametersTabStore(this);
        this.roles = new UserRolesTabStore(this);
        this.jurisdictions = new UserJurisdictionsTabStore(this);

        const filters = getObject<UsersViewModelLocalFilters>(`users-${this.dto.id}`);
        if (filters) {
            this._tabIndex = filters.tabIndex;
        }
        this.updateState().then()
    }

    saveFilters() {
        setObject<UsersViewModelLocalFilters>(`users-${this.dto.id}`, {
            tabIndex: this._tabIndex,
        });
    }

    get isDisabled(): boolean {
        return !this._store.store.store.authorizationStore.hasPermission(AllPermissions.user.update)
    }

    async updateState() {
        if (this.expand) {
            if (this._tabIndex === 0) {
                await this.parameters.form.fields.dcrGroup.pull()
            }
            if (this._tabIndex === 1) {
                await this.roles.pull()
            }
            if (this._tabIndex === 2) {
                await this.jurisdictions.pull()
            }
        }
    }
}
