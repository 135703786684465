import {PagedItems, TableStore} from "../../../../utils";
import {ElasticFeed, EntityType, FeedsControllerNewApi, FeedStatus} from "../../../../../services/analytics/exchange";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import moment from "moment/moment";
import {DefaultMultiSelectStore} from "../../../../../components/shared/DefaultMultiSelectStore";
import {getApiConfiguration, IdNameEntity} from "../../../../../core";
import {ClientApi, ClientDto} from "../../../../../services/dcr";
import {SpecialitiesApi, SpecialityDto, TypeOrgDto, TypeOrgsApi} from "../../../../../services/management";
import {AnalyticsPageStore} from "../../AnalyticsPageStore";
import {t} from "i18next";

export class ExchangeReportStore extends TableStore<ElasticFeed> {
    readonly isOpenFiler: DefaultValueStore<boolean> = new DefaultValueStore<boolean>(true, null);
    readonly startDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(moment(new Date()).startOf('month').toDate(), null, async (e) => await this.updateState());
    readonly endDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(new Date(), null, async (e) => await this.updateState());
    readonly entityType: DefaultMultiSelectStore<IdNameEntity<EntityType>> =
        new DefaultMultiSelectStore<IdNameEntity<EntityType>>([], (e) => Promise.resolve([
            {id: 'Person', name: "common.persons"},
            {id: 'Organization', name: "common.organizations"}
        ]), async (items) => await this.updateState(), null, "id", (e) => t(e.name))

    readonly speciality: DefaultMultiSelectStore<SpecialityDto> = new DefaultMultiSelectStore([],
        (filters) => new SpecialitiesApi(getApiConfiguration()).getSpecialities(filters),
        async (items) => await this.updateState());
    readonly orgType: DefaultMultiSelectStore<TypeOrgDto> = new DefaultMultiSelectStore([],
        (filters) => new TypeOrgsApi(getApiConfiguration()).getTypeOrgs(filters),
        async (items) => await this.updateState());
    readonly client: DefaultMultiSelectStore<ClientDto> = new DefaultMultiSelectStore<ClientDto>([],
        (e) => new ClientApi().apiDcrV1ClientsGet(e),
        async (items) => await this.updateState(),
        null,
        "id",
        "name")
    readonly status: DefaultMultiSelectStore<IdNameEntity<FeedStatus>> =
        new DefaultMultiSelectStore<IdNameEntity<FeedStatus>>([], (e) => Promise.resolve([
            {id: 'Succeeded', name: "common.succeeded"},
            {id: 'Failed', name: "common.failed"},
            // {id: 'InProgress', name: "common.inProgress"},
            // {id: 'Pending', name: "common.pending"},
            // {id: 'Enqueued', name: "common.enqueued"},
            // {id: 'Rejected', name: "common.rejected"},

        ]), async (items) => await this.updateState(), null, "id", (e) => t(e.name))

    constructor(store: AnalyticsPageStore) {
        super();
    }

    onFiltersSave(): void {
    }

    resetFilters(){
        this.startDate.reset()
        this.endDate.reset()
        this.entityType.reset()
        this.speciality.reset()
        this.orgType.reset()
        this.client.reset()
        this.status.reset()
    }

    async request(): Promise<PagedItems<ElasticFeed>> {
        const {items, total} = await new FeedsControllerNewApi().apiAnalyticsFeedsFindGet({
            page: this.page + 1,
            size: this.size,
            entityType: this.entityType.value?.length ? this.entityType.value.map(x => x.id) : ["Organization","Person"],
            from: this.startDate.value ? this.startDate.value : undefined,
            to: this.endDate.value ? this.endDate.value : undefined,
            clientIds: this.client.value?.length ? this.client.value.map(x => x.id) : undefined,
            status: this.status.value?.length ? this.status.value.map(x => x.id) : undefined,
            organizationTypeIds: this.orgType.value?.length ? this.orgType.value.map(x => x.id) : undefined,
            specialityIds: this.speciality.value?.length ? this.speciality.value.map(x => x.id) : undefined
        })

        return new PagedItems<ElasticFeed>(items ?? [], total)
    }
}
