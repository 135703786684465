import {makeAutoObservable} from "mobx";
import {AnalyticsPageStore} from "../../AnalyticsPageStore";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {ILoad} from "../../../../../core";
import {ClientApi, ClientDto} from "../../../../../services/dcr";
import {
    ClientsAggregation,
    ClientsAggregationHistogram,
    CountsHistogram,
    EntityType,
    EntityTypesAggregation,
    FeedsControllerNewApi,
    FeedStatus,
    OrganizationTypesAggregation,
    SpecialityAggregation,
    SummaryAggregation
} from "../../../../../services/analytics/exchange";
import {t} from "i18next";
import {debounce, DebouncedFunc} from "lodash";
import {LineChartData} from "../../../../../components/charts/line/LineChart";
import {TypeOrgDto} from "../../../../../services/management";
import {BarChartData} from "../../../../../components/charts/bar/BarChar";
import {AnalyticsDateFiltersStore} from "../../AnalyticsDateFiltersStore";

export class ExchangeDashboardsStore implements ILoad {
    store: AnalyticsPageStore;
    private readonly debounceRequest: DebouncedFunc<any> = debounce(this.request.bind(this), 800)
    dateFilters: AnalyticsDateFiltersStore = new AnalyticsDateFiltersStore(this);

    readonly client: DefaultSelectStore<ClientDto> = new DefaultSelectStore<ClientDto>(null,
        (e) => new ClientApi().apiDcrV1ClientsGet(e),
        null,
        "id",
        "name")

    loading: boolean = false;
    clientsCount: number = 0;
    errorsData: CountsHistogram[] = []
    exchangeData: CountsHistogram[] = []
    personsData: ClientsAggregation[] = []
    organizationsData: ClientsAggregation[] = []
    summaryExchangeData: EntityTypesAggregation | null = null;
    specialityData: SpecialityAggregation[] = []
    orgTypeData: OrganizationTypesAggregation[] = []
    personsErrorsData: ClientsAggregationHistogram[] = []
    organizationsErrorsData: ClientsAggregationHistogram[] = []
    summaryAggregationData: SummaryAggregation[] = []

    constructor(store: AnalyticsPageStore) {
        this.store = store;
        this.client.onChanged.push(async (e) => await this.requestClient())
     
        makeAutoObservable(this);
    }
    
    saveReportFilters(prop: { status?: FeedStatus[], entityType?: EntityType[], client?: string, orgType?: string, speciality?: string }) {
        this.store.exchangeReportStore.resetFilters()
        this.store.exchangeReportStore.startDate.setValueWithoutEffects(this.dateFilters.startDate.value!)
        this.store.exchangeReportStore.endDate.setValueWithoutEffects(this.dateFilters.endDate.value!)
        this.store.exchangeReportStore.status.setValueWithoutEffects(prop.status ? prop.status.map(x => ({
            id: x,
            name: ''
        })) : [])
        this.store.exchangeReportStore.entityType.setValueWithoutEffects(prop.entityType ? prop.entityType.map(x => ({
            id: x,
            name: ''
        })) : [])
        this.store.exchangeReportStore.speciality.setValueWithoutEffects(prop.speciality ? [{
            id: prop.speciality,
            nameRus: ''
        }] : [])
        this.store.exchangeReportStore.orgType.setValueWithoutEffects(prop.orgType ? [{
            id: prop.orgType,
            nameRus: ''
        } as TypeOrgDto] : [])
        this.store.exchangeReportStore.client.setValueWithoutEffects(prop.client ? [{
            boundId: prop.client,
            name: '',
            id: prop.client
        } as ClientDto] : [])
    }

  
    get valid(): boolean {
        return this.dateFilters.valid
    }

    get errorsCount(): number {
        return this.errorsData.length == 0 ? 0 :
            this.errorsData.map(x => x.count ?? 0).reduce((x, y) => x + y);
    }

    get exchangeCount(): number {
        return this.exchangeData.length == 0 ? 0 :
            this.exchangeData.map(x => x.count ?? 0).reduce((x, y) => x + y);
    }

    get personsPipeData(): {count: number, title: string}[] {
        return this.personsData.map(x => ({count: x.count ?? 0, title: x.clientName ?? ""}))
    }

    get organizationsPipeData(): {count: number, title: string}[] {
        return this.organizationsData.map(x => ({count: x.count ?? 0, title: x.clientName ?? ""}))
    }

    get summaryExchange(): LineChartData[] {
        return [
            {
                name: t("pageNames.persons"),
                data: this.summaryExchangeData?.persons ? this.dateFilters.formattingData(this.summaryExchangeData!.persons!.map(x => ({key: x.key!,count: x.count!}))) : []
            },
            {
                name: t("pageNames.organizations"),
                data: this.summaryExchangeData?.organizations ? this.dateFilters.formattingData(this.summaryExchangeData!.organizations!.map(x => ({key: x.key!,count: x.count!}))) : []
            }
        ]
    }

    get personsErrorsBarData(): BarChartData[] {
        return this.personsErrorsData.map(x => ({
            name: x.clientName!,
            data: x.histogram && x.histogram?.length > 0 ? this.dateFilters.formattingData(x.histogram!.map(x => ({key: x.key!,count: x.count!}))) : []
        }))
    }

    get organizationsErrorsBarData(): BarChartData[] {
        return this.organizationsErrorsData.map(x => ({
            name: x.clientName!,
            data: x.histogram && x.histogram?.length > 0 ? this.dateFilters.formattingData(x.histogram!.map(x => ({key: x.key!,count: x.count!}))) : []
        }))
    }

    async updateState(): Promise<void> {
        await this.pull()
    }

    async request(): Promise<void> {

        if (!this.valid)
            return;

        this.loading = true;

        const exchangeDashboardsApi = new FeedsControllerNewApi();

        const from = this.dateFilters.startDate.value ? this.dateFilters.startDate.value : undefined
        const to = this.dateFilters.endDate.value ? this.dateFilters.endDate.value : undefined


        Promise.all([
            exchangeDashboardsApi.apiAnalyticsFeedsErrorsCountHistogramGet({
                from: from,
                to: to,
                interval: this.dateFilters.interval == "1w" ? '1d' : this.dateFilters.interval
            }).then(x => this.errorsData = x),
            exchangeDashboardsApi.apiAnalyticsFeedsCountHistogramGet({
                from: from,
                to: to,
                interval: this.dateFilters.interval == "1w" ? '1d' : this.dateFilters.interval
            }).then(x => this.exchangeData = x),
            exchangeDashboardsApi.apiAnalyticsFeedsClientsAggregationGet({
                from: from,
                to: to,
                entityType: 'Person'
            }).then(x => this.personsData = x),
            exchangeDashboardsApi.apiAnalyticsFeedsClientsAggregationGet({
                from: from,
                to: to,
                entityType: 'Organization'
            }).then(x => this.organizationsData = x),
            this.requestClient(),
            exchangeDashboardsApi.apiAnalyticsFeedsClientsCountGet({
                from: from,
                to: to
            }).then(x => this.clientsCount = x)
        ]).then(x => this.loading = false)
    }

    async requestClient(): Promise<void> {
        const exchangeDashboardsApi = new FeedsControllerNewApi();

        const from = this.dateFilters.startDate.value ? this.dateFilters.startDate.value : undefined
        const to = this.dateFilters.endDate.value ? this.dateFilters.endDate.value : undefined
        const clientId = this.client.value ? this.client.value.id! : undefined;

        this.summaryExchangeData = await exchangeDashboardsApi.apiAnalyticsFeedsEntitytypesAggregationGet({
            from: from,
            to: to,
            interval: this.dateFilters.interval == "1w" ? '1d' : this.dateFilters.interval,
            clientIds: clientId ? [clientId] : undefined,
        })

        this.specialityData = await exchangeDashboardsApi.apiAnalyticsFeedsSpecialitiesAggregationGet({
            from: from,
            to: to,
            clientId: clientId,
        })
        this.orgTypeData = await exchangeDashboardsApi.apiAnalyticsFeedsOrganizationtypesAggregationGet({
            from: from,
            to: to,
            clientId: clientId
        })
        await this.updatePersonsPipe()
        await this.updateOrganizationsPipe()
        this.summaryAggregationData = await exchangeDashboardsApi.apiAnalyticsFeedsSummaryAggregationGet({
            from: from,
            to: to,
            clientId: clientId,
        })
    }

    async updatePersonsPipe() {
        const clientId = this.client.value ? this.client.value.boundId! : undefined;

        this.personsErrorsData = await new FeedsControllerNewApi().apiAnalyticsFeedsClientsAggregationHistogramErrorsGet({
            from: this.dateFilters.startDate.value ? this.dateFilters.startDate.value : undefined,
            to: this.dateFilters.endDate.value ? this.dateFilters.endDate.value : undefined,
            clientIds: clientId ? [clientId] : undefined,
            entityType: 'Person',
            interval: this.dateFilters.interval == "1w" ? '1d' : this.dateFilters.interval,
        })
    }

    async updateOrganizationsPipe() {
        const clientId = this.client.value ? this.client.value.boundId! : undefined;

        this.organizationsErrorsData = await new FeedsControllerNewApi().apiAnalyticsFeedsClientsAggregationHistogramErrorsGet({
            from: this.dateFilters.startDate.value ? this.dateFilters.startDate.value : undefined,
            to: this.dateFilters.endDate.value ? this.dateFilters.endDate.value : undefined,
            clientIds: clientId ? [clientId] : undefined,
            entityType: 'Organization',
            interval: this.dateFilters.interval == "1w" ? '1d' : this.dateFilters.interval,
        })
    }

    async pull(): Promise<void> {
        await this.debounceRequest()
    }
}
