/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface MissedRequestCommand
 */
export interface MissedRequestCommand {
    /**
     * 
     * @type {string}
     * @memberof MissedRequestCommand
     */
    clientId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissedRequestCommand
     */
    isPerson?: boolean;
}

export function MissedRequestCommandFromJSON(json: any): MissedRequestCommand {
    return MissedRequestCommandFromJSONTyped(json, false);
}

export function MissedRequestCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissedRequestCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'isPerson': !exists(json, 'isPerson') ? undefined : json['isPerson'],
    };
}

export function MissedRequestCommandToJSON(value?: MissedRequestCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'isPerson': value.isPerson,
    };
}

