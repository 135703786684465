import {Box, Card, Table, TableBody, TableHead, TableRow, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {
    AddButton,
    AuthorizePermission,
    Cell,
    ConfirmationForm,
    EmptyRow,
    ExpertDialog,
    HCell,
    PaginationLocal,
    ParamsButtonGroup,
    Search,
    TextInput
} from "../../../../components";
import {MenuComponent} from "../../../../components/MenuComponent";
import {MenuItemComponent} from "../../../../components/MenuItemComponent";
import {DeleteIcon} from "../../../../icons/deleteIcon";
import {Scrollbar} from "../../../../scrollbar";
import {MappingCreator} from "./MappingCreator";
import {NavLink} from "../../../../components/NavLink";
import {CopyToClipboard} from "../../../../components/CopyToClipboard";
import React from "react";
import {AllPermissions} from "../../../../core/const";
import {MappingTableStore} from "../../../../stores/management/clients/tabs/mappings/MappingTableStore";

interface Props<T extends { clientValue: string, entityId: string | number }> {
    store: MappingTableStore<T>
    entityName: string;
    withName?: boolean;
}

export const MappingTable = observer(<T extends { clientValue: string, entityId: string | number }>({
                                                                                                        store,
                                                                                                        entityName,
                                                                                                        withName = true
                                                                                                    }: Props<T>) => {
    const {t} = useTranslation();

    return (
        <> {
            store.current
                ? (
                    <ExpertDialog
                        title={t("common.delete")}
                        open={store.current.deleteDialogState}
                        onClose={() => store.current!.deleteDialogState = false}
                    >
                        <ConfirmationForm
                            disabled={store.current.processing}
                            onSubmit={async () => await store.delete()}
                            onClose={() => {
                                if (store.current) {
                                    store.current.deleteDialogState = false;
                                }
                            }}
                        >
                            {t('common.deleteConfirm')} "{store.current.name}"?
                        </ConfirmationForm>
                    </ExpertDialog>
                )
                : ''
        }

            <Box sx={{mb: 2}} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Search
                    autoFocus
                    placeholder="Поиск по Id"
                    style={{width: '87%', marginRight: 8}}
                    value={store.searchById.value}
                    onChange={t => store.searchById.value = t}
                />
                {
                    withName &&
                    <Search
                        placeholder="Поиск по имени"
                        style={{width: '87%', marginRight: 8}}
                        value={store.search.value}
                        onChange={t => store.search.value = t}
                    />
                }
                <AuthorizePermission permissions={AllPermissions.client.update}>
                    <AddButton onClick={() => store.creator.Open = true}/>
                </AuthorizePermission>
            </Box>


            <Card sx={{mb: 4}}>
                <ExpertDialog
                    title={t('clientPage.mappings.create')}
                    open={store.creator.Open}
                    onClose={() => store.creator.Open = false}
                >
                    <MappingCreator store={store.creator} entityName={entityName}/>
                </ExpertDialog>

                <Scrollbar>
                    <Table sx={{minWidth: 600}}>
                        <TableHead>
                            <TableRow>
                                <HCell style={{width: '2%'}}/>
                                <HCell testid="mappingTable-id"
                                       store={store}>{t('clientPage.mappings.table.id')}</HCell>
                                {
                                    withName && <HCell testid="mappingTable-name"
                                                       store={store}>{t('clientPage.mappings.table.name')}</HCell>
                                }
                                <HCell style={{width: '30%'}} testid="mappingTable-clientValue"
                                       store={store}>{t('clientPage.mappings.table.clientValue')}</HCell>
                                <HCell store={store} width='5%' style={{padding: '0 15px'}}
                                       testid="mappingTable-action">{t('table.action')}</HCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                store.items.length > 0
                                    ? store.items.map(x => (
                                        <>
                                            <TableRow
                                                hover
                                                key={x.id}
                                                onClick={() => store.current = x}
                                            >
                                                <Cell/>
                                                <Cell testid="mappingTable-id">
                                                    {
                                                        store.link ?
                                                            <>
                                                                <NavLink to={`/${store.link}/${x.id}`} target="_blank">
                                                                    <Typography
                                                                        style={{display: "flex", alignItems: 'center'}}
                                                                        variant="subtitle2">
                                                                        {x.id.toString()}
                                                                    </Typography>
                                                                </NavLink>
                                                            </> :

                                                            <Typography style={{display: "flex", alignItems: 'center'}}
                                                                        variant="subtitle2">
                                                                {`${x.id.toString().substring(0, 20)}`}
                                                                {x.id.toString().length > 20 && `...`}
                                                                {
                                                                    x.id.toString().length > 20 &&
                                                                    <CopyToClipboard copyText={x.id.toString()}/>
                                                                }
                                                            </Typography>
                                                    }
                                                </Cell>
                                                {
                                                    withName &&
                                                    <Cell testid="mappingTable-name">
                                                        {x.name}
                                                    </Cell>
                                                }
                                                <Cell testid="mappingTable-clientValue"
                                                      style={{width: `${(x.value!.length * 9) + 36}px`}}>
                                                    <TextInput
                                                        data-testid="mappingTable-nameRus-input"
                                                        outlined={false}
                                                        style={{marginRight: 20}}
                                                        value={x.value}
                                                        disabled={store.processing}
                                                        error={!x.valid}
                                                        errorText={x.error}
                                                        onChange={(t: any) => x.value = t}
                                                    />
                                                </Cell>
                                                <Cell align={'center'} testid="notes-action">
                                                    {store.items.length &&
                                                        <AuthorizePermission permissions={AllPermissions.client.update}>
                                                            <MenuComponent>
                                                                <MenuItemComponent text={t('common.delete')}
                                                                                   icon={<DeleteIcon fontSize="small"/>}
                                                                                   onClick={() => x.deleteDialogState = true}
                                                                                   style={{color: '#D14343'}}/>
                                                            </MenuComponent>
                                                        </AuthorizePermission>
                                                    }
                                                </Cell>
                                            </TableRow>
                                        </>
                                    ))
                                    : <EmptyRow/>
                            }
                        </TableBody>
                    </Table>
                    {store.items.length > 0 && <PaginationLocal store={store}/>}
                </Scrollbar>

            </Card>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <AuthorizePermission permissions={AllPermissions.client.update}>
                    <ParamsButtonGroup
                        testid="mappingTable"
                        onCancel={() => store.reset()}
                        onSave={async () => await store.save()}
                        isCancelBtnDisabled={store.processing || store.equals}
                        isSaveBtnDisabled={store.processing || store.equals || !store.valid}

                    />
                </AuthorizePermission>
            </Box>
        </>
    );
});
