/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    DcrGroupListDto,
    DcrGroupListDtoFromJSON,
    DcrGroupListDtoFromJSONTyped,
    DcrGroupListDtoToJSON,
} from './DcrGroupListDto';

/**
 * 
 * @export
 * @interface DcrGroupListDtoPagedResponse
 */
export interface DcrGroupListDtoPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof DcrGroupListDtoPagedResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<DcrGroupListDto>}
     * @memberof DcrGroupListDtoPagedResponse
     */
    items?: Array<DcrGroupListDto> | null;
}

export function DcrGroupListDtoPagedResponseFromJSON(json: any): DcrGroupListDtoPagedResponse {
    return DcrGroupListDtoPagedResponseFromJSONTyped(json, false);
}

export function DcrGroupListDtoPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DcrGroupListDtoPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(DcrGroupListDtoFromJSON)),
    };
}

export function DcrGroupListDtoPagedResponseToJSON(value?: DcrGroupListDtoPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(DcrGroupListDtoToJSON)),
    };
}

