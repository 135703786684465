import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {
    Box,
    Card,
    FormControlLabel,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery
} from "@mui/material";
import {RequestTableStore} from "../../../stores/management/dcr/requests/RequestTableStore";
import {
    Cell,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Pagination,
    Progress,
    Search,
    SimpleButton
} from "../../../components";
import {RequestStatusEnum} from "../../../stores/management/dcr/types";
import {DefaultMultiSelect} from "../../../components/shared/components/DefaultMultiSelect";
import {CopyToClipboard} from "../../../components/CopyToClipboard";
import {DefaultSelect} from "../../../components/shared/components/DefaultSelect";
import React, {Fragment, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import {SeverityPill, SeverityPillColor} from "../../../components/severity-pill";
import {RequestStatus} from "../../../services/dcr";
import {DcrDrawer} from "../DcrDrawer";
import {styled} from '@mui/material/styles';
import {RequestDetails} from "./RequestDetails";
import {ArrowRight as ArrowRightIcon} from "../../../icons/arrow-right";
import {DefaultDate} from "../../../components/shared/DefaultDate";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {RequestComments} from "./forms/RequestComments";
import MessageIcon from '@mui/icons-material/Message';
import MatchIcon from '@mui/icons-material/JoinInner';
import {FiltersComponent} from "../../../components/filters/FiltersComponent";
import {useRootStore} from "../../../stores";

interface Props {
    requestTableStore: RequestTableStore;
}

export const requestStatusHandler = (status: RequestStatus) => {
    switch (status) {
        case RequestStatusEnum.WaitingVerification:
            return 'success'
        case RequestStatusEnum.OnVerification:
            return 'info'
        case RequestStatusEnum.Verified:
            return 'secondary'
        case RequestStatusEnum.Decline:
            return 'error'
        case RequestStatusEnum.Double:
            return 'error'
    }
    return 'success'
}

export const DcrListInner = styled(
    'div',
    {shouldForwardProp: (prop) => prop !== 'open'}
)<{ open?: boolean; }>(
    ({theme, open}) => ({
        flexGrow: 1,
        overflow: 'hidden',
        paddingBottom: theme.spacing(8),
        paddingTop: theme.spacing(2),
        zIndex: 1,
        // [theme.breakpoints.up('lg')]: {
        //     marginRight: '-80%'
        // },
        // transition: theme.transitions.create('margin', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen
        // }),


        // ...(open && {
        //     [theme.breakpoints.up('lg')]: {
        //         marginRight: 0
        //     },
        //     transition: theme.transitions.create('margin', {
        //         easing: theme.transitions.easing.easeOut,
        //         duration: theme.transitions.duration.enteringScreen
        //     })
        // })
    })
);


export const RequestTable = observer(({requestTableStore}: Props) => {
    const {t} = useTranslation();
    const rootRef = useRef<HTMLDivElement | null>(null);

    const matches = useMediaQuery((theme: any) => {
        return theme.breakpoints.down('1700')
    });
    const {signalRStore} = useRootStore();

    useEffect(() => {
        requestTableStore.resetDefaultGroup()

        const fetchData = async () => {
            await signalRStore.startConnection("dcr")
        }
        fetchData()
        return () => {
            signalRStore.stopConnection("dcr")
        }
    }, [])

    return (
        <Box style={{position: 'relative'}}>
            {
                <ExpertDialog
                    open={requestTableStore.commentModel.Open}
                    size={'md'}
                    hasTitle={false}
                    onClose={() => requestTableStore.commentModel.closeModal()}
                >
                    <RequestComments store={requestTableStore.commentModel}/>
                </ExpertDialog>
            }

            <Box style={{flex: 1}}>
                <FiltersComponent>
                    <Box sx={{mt: 3}}
                         style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Search
                            autoFocus
                            value={requestTableStore.idValue.value!}
                            onChange={(t) => requestTableStore.idValue.value = t}
                            placeholder='Id'
                            style={{width: '24%'}}
                            testid="requestTable-id"
                        />
                        <Search
                            value={requestTableStore.entityIdIdValue.value!}
                            onChange={(t) => requestTableStore.entityIdIdValue.value = t}
                            placeholder='Data360 Id'
                            style={{width: '24%'}}
                            testid="requestTable-crmId"
                        />
                        <Search
                            value={requestTableStore.externalIdValue.value!}
                            onChange={(t) => requestTableStore.externalIdValue.value = t}
                            placeholder='External Id'
                            style={{width: '24%'}}
                            testid="requestTable-externalId"
                        />
                        <Search
                            value={requestTableStore.crmIdValue.value!}
                            onChange={(t) => requestTableStore.crmIdValue.value = t}
                            placeholder='Crm Id'
                            style={{width: '24%'}}
                            testid="requestTable-crmId"
                        />
                    </Box>
                    <Box sx={{mt: 1}}
                         style={{display: 'flex', justifyContent: 'space-between', marginTop: 'unset'}}
                    >
                        <div
                            style={{width: '32%', display: 'flex', justifyContent: 'space-between', marginTop: 16}}>
                            <DefaultDate store={requestTableStore.startDate}
                                         style={{width: '48%'}}
                                         title={t('dcrPage.requestTable.startDate')}
                                         testid="requestTable-startDate"/>
                            <DefaultDate store={requestTableStore.endDate}
                                         style={{width: '48%'}}
                                         title={t('dcrPage.requestTable.endDate')}
                                         testid="requestTable-endDate"/>
                        </div>
                        <DefaultSelect store={requestTableStore.dcrGroup}
                                       title={t('dcrPage.requestTable.dcrGroup')}
                                       nameExp="name"
                                       valueExp="id"
                                       testid="requestTable-dcrGroup"
                                       hasLabel
                                       style={{width: '32%', marginTop: 8}}
                        />
                        <DefaultMultiSelect store={requestTableStore.client}
                                            title={t('dcrPage.requestTable.client')}
                                            nameExp="name"
                                            valueExp="name"
                                            style={{width: '32%'}}
                                            testid="requestTable-client"
                        />
                    </Box>

                    <Box sx={{mb: 1}}
                         style={{display: 'flex', justifyContent: 'space-between', marginBottom: 10, marginTop: -8}}
                    >
                        <DefaultMultiSelect store={requestTableStore.requestStatus}
                                            title={t('dcrPage.requestTable.state')}
                                            nameExp={requestTableStore.requestStatus.nameExp}
                                            valueExp="value"
                                            style={{width: '32%'}}
                                            testid="requestTable-requestStatus"
                        />
                        <DefaultMultiSelect store={requestTableStore.requestRecordType}
                                            title={t('dcrPage.requestTable.typeRequest')}
                                            nameExp={requestTableStore.requestRecordType.nameExp}
                                            valueExp="value"
                                            style={{width: '32%'}}
                                            testid="requestTable-typeRequest"
                        />
                        <div style={{width: '32%', display: 'flex', justifyContent: 'space-between'}}>
                            <DefaultMultiSelect store={requestTableStore.dcrStatus}
                                                title={t('dcrPage.requestTable.dcrStatus')}
                                                nameExp={requestTableStore.dcrStatus.nameExp}
                                                valueExp="value"
                                                testid="requestTable-dcrStatus"
                                                style={!requestTableStore.selectedAz ? {width: '100%'} : {
                                                    width: '49%',
                                                    minWidth: "unset"
                                                }}
                            />
                            {
                                requestTableStore.selectedAz &&
                                <DefaultMultiSelect store={requestTableStore.azType}
                                                    title={t('dcrPage.requestTable.azType')}
                                                    nameExp={requestTableStore.azType.nameExp}
                                                    style={{width: '49%', minWidth: "unset"}}
                                                    valueExp=""
                                                    testid="requestTable-azType"

                                />
                            }
                        </div>
                    </Box>
                    <Box sx={{mb: 1}}
                         style={{display: 'flex', justifyContent: 'space-between', marginBottom: 10, marginTop: -8}}
                    >
                        <DefaultMultiSelect store={requestTableStore.responsible}
                                            title={t('dcrPage.requestTable.responsible')}
                                            nameExp="name"
                                            valueExp="name"
                                            style={{width: '32%'}}
                                            testid="requestTable-responsible"
                        />
                        <DefaultSelect store={requestTableStore.correctRequest}
                                       title={t('dcrPage.requestTable.correct')}
                                       nameExp={requestTableStore.correctRequest.nameExp}
                                       valueExp="value"
                                       hasLabel
                                       style={{width: '32%', marginTop: 8}}
                        />

                        <Box style={{
                            width: '32%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <FormControlLabel
                                labelPlacement="end"
                                label={
                                    <Typography variant="subtitle2" style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {t('common.openDcr')}
                                    </Typography>
                                }
                                title={matches ? t('common.openDcr') : ""}
                                style={!requestTableStore.selectedAz ? {width: '50%'} : {
                                    width: '37%',
                                    marginLeft: 0,
                                    marginTop: 12,
                                    marginRight: 5
                                }}
                                control={
                                    <Switch
                                        checked={requestTableStore.isOpenInDrawer.value!}
                                        onClick={() => {
                                            requestTableStore.isOpenInDrawer.value = !requestTableStore.isOpenInDrawer.value;
                                            if (requestTableStore.isOpenInDrawer.value && !!requestTableStore.openRequest) {
                                                requestTableStore.items.map(x => x.expand = false);
                                            }
                                        }}/>
                                }
                            />
                            <FormControlLabel
                                labelPlacement="end"
                                label={
                                    <Typography variant="subtitle2" style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {t('dcrPage.requestTable.requestsAcceptedByMe')}
                                    </Typography>
                                }
                                title={matches ? t('dcrPage.requestTable.requestsAcceptedByMe') : ""}
                                // label={!requestTableStore.isAcceptedByMe ? t('dcrPage.requestTable.requestsAcceptedByMe') : t('dcrPage.requestTable.allRequests')}
                                style={!requestTableStore.selectedAz ? {width: '50%'} : {
                                    width: '33%',
                                    marginLeft: 0,
                                    marginTop: 12,
                                    marginRight: 5
                                }}
                                control={
                                    <Switch
                                        checked={requestTableStore.isAcceptedByMe.value!}
                                        onClick={() => {
                                            requestTableStore.isAcceptedByMe.value = !requestTableStore.isAcceptedByMe.value
                                        }}/>
                                }
                            />
                            {
                                requestTableStore.selectedAz &&
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle2" style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {t('common.escalation')}
                                        </Typography>
                                    }
                                    title={matches ? t('common.escalation') : ""}
                                    labelPlacement="end"
                                    style={{width: '30%', marginRight: 0, marginTop: 12}}
                                    control={
                                        <Switch
                                            checked={requestTableStore.isEscalation.value!}
                                            onClick={() => requestTableStore.isEscalation.value = !requestTableStore.isEscalation.value}/>
                                    }
                                />
                            }

                        </Box>
                    </Box>
                </FiltersComponent>


                <Card sx={{mb: 8, overflow: 'unset'}}>
                    <Table sx={{minWidth: 1250}}>
                        <TableHead style={{position: 'sticky', top: 63, zIndex: 999}}>
                            <TableRow>
                                <HCell width='5%' testid="requestTable-expand"/>
                                <HCell store={requestTableStore}
                                       testid="requestTable-requestId">{t('dcrPage.requestTable.requestId')}</HCell>
                                <HCell orderBy="client" testid="requestTable-client"
                                       store={requestTableStore}>{t('dcrPage.requestTable.client')}</HCell>
                                <HCell orderBy="dcrStatus" testid="requestTable-dcrStatus"
                                       store={requestTableStore}>{t('dcrPage.requestTable.dcrStatus')}</HCell>
                                <HCell orderBy="requestRecordType" testid="requestTable-typeRequest"
                                       store={requestTableStore}>{t('dcrPage.requestTable.typeRequest')}</HCell>
                                <HCell orderBy="createdAt" testid="requestTable-createdAt"
                                       store={requestTableStore}>{t('dcrPage.requestTable.createdAt')}</HCell>
                                <HCell orderBy="modifiedAt" testid="requestTable-modifiedAt"
                                       store={requestTableStore}>{t('dcrPage.requestTable.modifiedAt')}</HCell>
                                <HCell orderBy="requestStatus" testid="requestTable-state"
                                       store={requestTableStore}>{t('dcrPage.requestTable.state')}</HCell>
                                <HCell store={requestTableStore}
                                       testid="requestTable-responsible">{t('dcrPage.requestTable.responsible')}</HCell>
                                <HCell style={{width: 150}} testid="requestTable-action"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                requestTableStore.items.length > 0
                                && requestTableStore.items.map(i => {
                                    return <Fragment key={i.dto.id}>
                                        <TableRow
                                            hover
                                            key={i.dto.id}
                                            style={{backgroundColor: i.rowColor}}
                                        >
                                            <ExpandButton item={i}
                                                          testid="requestTable"
                                                          isOpen={i.expand}
                                                          isDcr={!!requestTableStore.isOpenInDrawer.value}
                                                          onClick={() => {
                                                              if (requestTableStore.isOpenDrawer && !i.expand) {
                                                                  requestTableStore.openRequest!.expand = false
                                                              }
                                                          }}
                                            />
                                            <Cell style={{paddingRight: 10, marginRight: 10}}
                                                  testid="requestTable-requestId">
                                                <Typography variant="subtitle2"
                                                            style={{display: "flex", alignItems: 'center'}}>
                                                    {`${i.dto.id.substring(0, 11)}...`}
                                                    <CopyToClipboard copyText={i.dto.id}/>
                                                </Typography>
                                            </Cell>

                                            <Cell testid="requestTable-client">
                                                <Typography variant="subtitle2" style={{color: i.fontColor}}>
                                                    {i.dto.client}
                                                </Typography>
                                            </Cell>

                                            <Cell testid="requestTable-dcrStatus">
                                                <Typography variant="subtitle2" style={{color: i.fontColor}}>
                                                    {i.dcrStatusName}
                                                </Typography>
                                            </Cell>

                                            <Cell testid="requestTable-typeRequest">
                                                <Typography variant="subtitle2" style={{color: i.fontColor}}>
                                                    {i.typesName}
                                                </Typography>
                                            </Cell>
                                            <Cell testid="requestTable-createdAt">
                                                <Typography variant="subtitle2" style={{color: i.fontColor}}>
                                                    {i.createdDate}
                                                </Typography>
                                            </Cell>
                                            <Cell testid="requestTable-modifiedDate">
                                                <Typography variant="subtitle2" style={{color: i.fontColor}}>
                                                    {i.modifiedDate}
                                                </Typography>
                                            </Cell>
                                            <Cell testid="requestTable-state">
                                                <SeverityPill
                                                    color={requestStatusHandler(i.dto.status) as SeverityPillColor}>
                                                    {i.statusName}
                                                </SeverityPill>
                                            </Cell>
                                            <Cell testid="requestTable-responsible">
                                                <Typography variant="subtitle2"
                                                            style={{display: "flex", alignItems: 'center'}}>
                                                    {i.dto.responsibleName}
                                                </Typography>
                                            </Cell>
                                            <Cell testid="requestTable-action">
                                                {


                                                    <div style={{display: 'flex'}}>
                                                        <div>
                                                            <Link to="/dcr" target="_blank" style={{ textDecoration: 'none' }}>
                                                                <IconButton color="primary"
                                                                            style={{marginRight: 5}}
                                                                            component="label">
                                                                    <span style={{fontSize: 18, paddingRight: 5}}>{i.dto.entityRequestCount}</span>
                                                                    <MatchIcon fontSize="small"/>
                                                                </IconButton>
                                                            </Link>
                                                        </div>
                                                        <div
                                                            onClick={async () => await requestTableStore.commentModel.openModal(i)}>
                                                            <IconButton color="primary"
                                                                        style={{marginRight: 5}}
                                                                        component="label"
                                                            >
                                                                    <span style={{
                                                                        fontSize: 18,
                                                                        paddingRight: 5
                                                                    }}>{i.dto.commentCount}</span>
                                                                <MessageIcon fontSize="small"/>
                                                            </IconButton>

                                                        </div>

                                                        {
                                                            i.takeUnTake &&
                                                            <>
                                                                {
                                                                    !i.isEndStatus &&
                                                                    <Tooltip title={t("dcrPage.checkCorrect")}
                                                                             placement="top">
                                                                        <IconButton color="primary"
                                                                                    style={{marginRight: 5}}
                                                                                    component="label"
                                                                                    onClick={async () => await i.reloadCorrect()}>
                                                                            <AutorenewIcon fontSize="small"/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    <SimpleButton
                                                                        onClick={async () => await i.onPinOrUnpin()}
                                                                        title={t(i.isTake ? 'dcrPage.requestTable.untake' : 'dcrPage.requestTable.take')}
                                                                        endIcon={i.isTake ? <></> :
                                                                            <ArrowRightIcon
                                                                                fontSize="small"/>}
                                                                        style={{width: 135, marginLeft: 'auto'}}
                                                                    />
                                                                }
                                                            </>
                                                        }

                                                    </div>
                                                }
                                            </Cell>
                                        </TableRow>
                                        {i.expand && !requestTableStore.isOpenInDrawer.value && (
                                            <ExpandRow key={`${i.dto.id}_`} item={i}>
                                                <RequestDetails
                                                    onClose={() => i.expand = false}
                                                    store={i.details}
                                                    style={{
                                                        margin: '20px 12px 0 20px'
                                                    }}
                                                    isOpenInDrawer={!!requestTableStore.isOpenInDrawer.value}
                                                />
                                            </ExpandRow>)}
                                    </Fragment>
                                })
                            }
                        </TableBody>

                    </Table>
                    {!requestTableStore.items.length && <EmptyBox/>}
                    {requestTableStore.items.length > 0 && <Pagination store={requestTableStore}/>}

                    <Progress store={requestTableStore}/>

                </Card>
            </Box>
            <DcrDrawer open={requestTableStore.isOpenDrawer}
                       onClose={() => requestTableStore.openRequest!.expand = false}>
                {requestTableStore.openRequest ?
                    <RequestDetails
                        onClose={() => requestTableStore.openRequest!.expand = false}
                        store={requestTableStore.openRequest!.details}
                        style={{
                            margin: '20px 12px 0 20px'
                        }}
                        isOpenInDrawer={!!requestTableStore.isOpenInDrawer.value}
                    /> : <></>}
            </DcrDrawer>
        </Box>
    );
});
