/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    DcrStatus,
    DcrStatusFromJSON,
    DcrStatusFromJSONTyped,
    DcrStatusToJSON,
} from './DcrStatus';
import {
    RequestRecordType,
    RequestRecordTypeFromJSON,
    RequestRecordTypeFromJSONTyped,
    RequestRecordTypeToJSON,
} from './RequestRecordType';
import {
    RequestStatus,
    RequestStatusFromJSON,
    RequestStatusFromJSONTyped,
    RequestStatusToJSON,
} from './RequestStatus';
import {
    ResponseStatus,
    ResponseStatusFromJSON,
    ResponseStatusFromJSONTyped,
    ResponseStatusToJSON,
} from './ResponseStatus';

/**
 * 
 * @export
 * @interface ResponseDto
 */
export interface ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    requestId: string;
    /**
     * 
     * @type {Date}
     * @memberof ResponseDto
     */
    creationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    readonly nCreateDate: string;
    /**
     * 
     * @type {RequestStatus}
     * @memberof ResponseDto
     */
    requestStatus: RequestStatus;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    readonly requestStatusLabel?: string | null;
    /**
     * 
     * @type {ResponseStatus}
     * @memberof ResponseDto
     */
    status: ResponseStatus;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    readonly statusLabel?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ResponseDto
     */
    modifiedDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    readonly nModifiedDate: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    readonly nModifiedDateWithTime: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    comment?: string | null;
    /**
     * 
     * @type {RequestRecordType}
     * @memberof ResponseDto
     */
    requestType: RequestRecordType;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    readonly typeLabel?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseDto
     */
    externalId: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    value: string;
    /**
     * 
     * @type {DcrStatus}
     * @memberof ResponseDto
     */
    dcrStatus?: DcrStatus;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    readonly dcrStatusLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    client?: string | null;
}

export function ResponseDtoFromJSON(json: any): ResponseDto {
    return ResponseDtoFromJSONTyped(json, false);
}

export function ResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestId': json['requestId'],
        'creationDate': (new Date(json['creationDate'])),
        'nCreateDate': json['nCreateDate'],
        'requestStatus': RequestStatusFromJSON(json['requestStatus']),
        'requestStatusLabel': !exists(json, 'requestStatusLabel') ? undefined : json['requestStatusLabel'],
        'status': ResponseStatusFromJSON(json['status']),
        'statusLabel': !exists(json, 'statusLabel') ? undefined : json['statusLabel'],
        'modifiedDate': (new Date(json['modifiedDate'])),
        'nModifiedDate': json['nModifiedDate'],
        'nModifiedDateWithTime': json['nModifiedDateWithTime'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'requestType': RequestRecordTypeFromJSON(json['requestType']),
        'typeLabel': !exists(json, 'typeLabel') ? undefined : json['typeLabel'],
        'externalId': json['externalId'],
        'field': json['field'],
        'value': json['value'],
        'dcrStatus': !exists(json, 'dcrStatus') ? undefined : DcrStatusFromJSON(json['dcrStatus']),
        'dcrStatusLabel': !exists(json, 'dcrStatusLabel') ? undefined : json['dcrStatusLabel'],
        'client': !exists(json, 'client') ? undefined : json['client'],
    };
}

export function ResponseDtoToJSON(value?: ResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestId': value.requestId,
        'creationDate': (value.creationDate.toISOString()),
        'requestStatus': RequestStatusToJSON(value.requestStatus),
        'status': ResponseStatusToJSON(value.status),
        'modifiedDate': (value.modifiedDate.toISOString()),
        'comment': value.comment,
        'requestType': RequestRecordTypeToJSON(value.requestType),
        'externalId': value.externalId,
        'field': value.field,
        'value': value.value,
        'dcrStatus': DcrStatusToJSON(value.dcrStatus),
        'client': value.client,
    };
}

