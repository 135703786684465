/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface CommentDto
 */
export interface CommentDto {
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    text?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CommentDto
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    userName?: string | null;
}

export function CommentDtoFromJSON(json: any): CommentDto {
    return CommentDtoFromJSONTyped(json, false);
}

export function CommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
    };
}

export function CommentDtoToJSON(value?: CommentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'name': value.name,
        'userName': value.userName,
    };
}

