/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';

/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    defaultDcrGroupId?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    name?: string | null;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': !exists(json, 'id') ? undefined : json['id'],
        'defaultDcrGroupId': !exists(json, 'defaultDcrGroupId') ? undefined : json['defaultDcrGroupId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'defaultDcrGroupId': value.defaultDcrGroupId,
        'name': value.name,
    };
}

