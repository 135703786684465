/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *
 * @export
 */
export const SourceType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type SourceType = typeof SourceType[keyof typeof SourceType];


export function SourceTypeFromJSON(json: any): SourceType {
    return SourceTypeFromJSONTyped(json, false);
}

export function SourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceType {
    return json as SourceType;
}

export function SourceTypeToJSON(value?: SourceType | null): any {
    return value as any;
}

