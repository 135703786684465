/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    Request,
    RequestFromJSON,
    RequestFromJSONTyped,
    RequestToJSON,
} from './Request';

/**
 * 
 * @export
 * @interface DeleteRequestsDto
 */
export interface DeleteRequestsDto {
    /**
     * 
     * @type {number}
     * @memberof DeleteRequestsDto
     */
    totalDeleteCount?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof DeleteRequestsDto
     */
    clientDeleteCount?: { [key: string]: number; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: Array<Request>; }; }}
     * @memberof DeleteRequestsDto
     */
    deletedEntities?: { [key: string]: { [key: string]: Array<Request>; }; } | null;
}

export function DeleteRequestsDtoFromJSON(json: any): DeleteRequestsDto {
    return DeleteRequestsDtoFromJSONTyped(json, false);
}

export function DeleteRequestsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteRequestsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalDeleteCount': !exists(json, 'totalDeleteCount') ? undefined : json['totalDeleteCount'],
        'clientDeleteCount': !exists(json, 'clientDeleteCount') ? undefined : json['clientDeleteCount'],
        'deletedEntities': !exists(json, 'deletedEntities') ? undefined : json['deletedEntities'],
    };
}

export function DeleteRequestsDtoToJSON(value?: DeleteRequestsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalDeleteCount': value.totalDeleteCount,
        'clientDeleteCount': value.clientDeleteCount,
        'deletedEntities': value.deletedEntities,
    };
}

