/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface AzRequestsExportCommand
 */
export interface AzRequestsExportCommand {
    /**
     * 
     * @type {string}
     * @memberof AzRequestsExportCommand
     */
    clientId?: string | null;
}

export function AzRequestsExportCommandFromJSON(json: any): AzRequestsExportCommand {
    return AzRequestsExportCommandFromJSONTyped(json, false);
}

export function AzRequestsExportCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AzRequestsExportCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
    };
}

export function AzRequestsExportCommandToJSON(value?: AzRequestsExportCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
    };
}

