/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';

/**
 *
 * @export
 * @interface SetUserDefaultDcrGroupDto
 */
export interface SetUserDefaultDcrGroupDto {
    /**
     *
     * @type {string}
     * @memberof SetUserDefaultDcrGroupDto
     */
    userId?: string | null;
    /**
     *
     * @type {string}
     * @memberof SetUserDefaultDcrGroupDto
     */
    groupId?: string | null;
}

export function SetUserDefaultDcrGroupDtoFromJSON(json: any): SetUserDefaultDcrGroupDto {
    return SetUserDefaultDcrGroupDtoFromJSONTyped(json, false);
}

export function SetUserDefaultDcrGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetUserDefaultDcrGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
    };
}

export function SetUserDefaultDcrGroupDtoToJSON(value?: SetUserDefaultDcrGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'userId': value.userId,
        'groupId': value.groupId,
    };
}

