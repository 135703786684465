/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetUserDefaultDcrGroupDto,
    SetUserDefaultDcrGroupDtoFromJSON,
    SetUserDefaultDcrGroupDtoToJSON,
    UserDto,
    UserDtoFromJSON,
    UserDtoToJSON,
} from '../models';

export interface GetUserRequest {
    userId?: string;
}

export interface SetUserDefaultDcrGroupRequest {
    setUserDefaultDcrGroupDto?: SetUserDefaultDcrGroupDto;
}

/**
 *
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async getUser(requestParameters: GetUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDto> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setUserDefaultDcrGroupRaw(requestParameters: SetUserDefaultDcrGroupRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetUserDefaultDcrGroupDtoToJSON(requestParameters.setUserDefaultDcrGroupDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async setUserDefaultDcrGroup(requestParameters: SetUserDefaultDcrGroupRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDto> {
        const response = await this.setUserDefaultDcrGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
