import {makeAutoObservable} from "mobx";
import {ClientItemStore} from "../ClientItemStore";
import {IHasTabs, setObject} from "../../../../core";
import {ClientFragment} from "../../../../gql/graphql";
import {ClientBoundStore} from "../tabs/bounds/ClientBoundStore";
import {MappingsStore} from "../tabs/mappings/MappingsStore";
import {ClientJurisdictionsStore} from "../tabs/ClientJurisdictionsStore";
import {ClientDetailsStore} from "../tabs/ClientDetailsStore";
import {ClientDcrSettingsStore} from "../tabs/ClientDcrSettingsStore";
import {ClientCrmSettingsStore} from "../tabs/ClientCrmSettingsStore";

interface ClientStoreLocalFilters {
    tabIndex: number;
}

export class ClientStore implements IHasTabs {
    readonly _store: ClientItemStore;
    dto: ClientFragment;
    id: string;
    private _tabIndex = 0;

    bounds: ClientBoundStore;
    mappings: MappingsStore;
    jurisdictionsStore: ClientJurisdictionsStore;
    detailsStore: ClientDetailsStore;
    dcrSettingsStore: ClientDcrSettingsStore;
    crmSettingsStore: ClientCrmSettingsStore;

    constructor(store: ClientItemStore, dto: ClientFragment) {
        this._store = store;
        this.dto = dto;
        this.id = dto.id;

        this.detailsStore = new ClientDetailsStore(this, dto);
        this.dcrSettingsStore = new ClientDcrSettingsStore(this, dto);
        this.crmSettingsStore = new ClientCrmSettingsStore(this, dto);
        this.bounds = new ClientBoundStore(this);
        this.mappings = new MappingsStore(this, dto);
        this.jurisdictionsStore = new ClientJurisdictionsStore(dto);

        makeAutoObservable(this);
    }

    get tabIndex() {
        return this._tabIndex;
    }

    set tabIndex(value: number) {
        this._tabIndex = value;
        this.saveFilters();
        this.updateState()
    }

    async updateState() {
        if (this.tabIndex == 0) {
            await this.bounds.pull()
        }
        if (this.tabIndex == 5) {
            await this.jurisdictionsStore.pull()
        }
        if (this.tabIndex == 4) {
            await this.mappings.updateState()
        }
    }

    get orgName() {
        return this.dto.orgName
    }

    update(dto: ClientFragment) {
        this.dto = dto;
        this.detailsStore.update(dto)
        this.dcrSettingsStore.update(dto)
        this.crmSettingsStore.update(dto)
    }

    saveFilters() {
        setObject<ClientStoreLocalFilters>(`client-store-${this.id}`, {
            tabIndex: this._tabIndex,
        });
    }
}

