import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {Button, List} from "@mui/material";
import {DefaultValue} from "../../../../components/shared/components/DefaultValue";
import {AzSpecification} from "../../../../stores/management/dcr/requests/specifications/clients/AzSpecification";
import {SimpleButton} from "../../../../components";
import {RequestStatusEnum} from "../../../../stores/management/dcr/types";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {DcrListItem} from "../../DcrListItem";
import {
    mappingDcrStatusToString,
    mappingRequestStatusToString,
    mappingRequestTypeToString
} from "../../../../stores/management/dcr/services";
import {SeverityPill} from "../../../../components/severity-pill";
import {useRootStore} from "../../../../stores";

interface Props {
    store: AzSpecification;
}

export const AzDetailsBeforeComponent = observer(({store}: Props) => {
    const {t} = useTranslation();
    const {themeColorStore} = useRootStore();

    return (
        <>
            {
                store.store.subRequests.length > 0 &&
                <div style={{ margin: '0 40px', marginTop: !store.store.edit && store.store.dto.status != RequestStatusEnum.WaitingVerification ? 20: 0 }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ fontWeight: 'bold', fontSize: 17 }}>{t('common.escalation')}</div>
                    </div>
                    <div style={{marginTop: 20}}>
                        {
                            store.store.subRequests.map((x, index) =>
                                <Accordion style={{
                                    backgroundColor: themeColorStore.themeSettings == 'dark' ? '#1c2536' : 'rgb(148 174 223)',
                                    marginTop: 10,
                                }} expanded={!!(x as any)['expanded']}
                                           onChange={(e, isExpanded) => (x as any)['expanded'] = isExpanded}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div style={{marginRight: 5}}>{t("dcrPage.request")}{!(x as any)['expanded'] ? " - " + x.id : ""}</div>
                                        {
                                            !(x as any)['expanded'] &&
                                            <>
                                                <SeverityPill color="info" style={{margin: '0 5px'}}>{mappingDcrStatusToString(x.dcrStatus)}</SeverityPill>
                                                <SeverityPill color="info" style={{margin: '0 5px'}}>{mappingRequestTypeToString(x.type)}</SeverityPill>
                                                <SeverityPill color="info" style={{margin: '0 5px'}}>{mappingRequestStatusToString(x.status)}</SeverityPill>
                                                <SeverityPill color="info" style={{margin: '0 5px'}}>{x.date}</SeverityPill>
                                            </>
                                        }
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{display: "flex"}}>
                                            <List style={{width: '50%'}}>
                                                <DcrListItem name={"Id"} value={x.id}/>
                                                <DcrListItem name={t('dcrPage.status')} value={mappingRequestStatusToString(x.status) ?? ""}/>
                                                <DcrListItem name={t('dcrPage.dcrStatus')} value={mappingDcrStatusToString(x.dcrStatus) ?? ""}/>
                                                <DcrListItem name={t('dcrPage.typeRequest')} value={mappingRequestTypeToString(x.type) ?? ""}/>
                                                <DcrListItem name={t('dcrPage.dcrDate')} value={x.date ?? ""}/>
                                                <DcrListItem name={t('Crm Id')} value={x.crmId ?? ""}/>
                                            </List>
                                            <div style={{marginRight: 30, width: '50%'}}>
                                                <div style={{ color: 'gray' }}>{t("dcrPage.comment")}</div>
                                                <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {x.comment ? x.comment : t("dcrPage.empty")}
                                                </div>
                                            </div>
                                        </div>

                                    </AccordionDetails>
                                </Accordion>
                            )
                        }
                    </div>
                </div>
            }
        </>
    );
});


export const AzDetailsButtonsComponent = observer(({store}: Props) => {
    const {t} = useTranslation();

    return (
        <>
            {
                store.store.edit &&
                <>
                    <SimpleButton
                        onClick={async () => {
                            await store.changeStatus(RequestStatusEnum.Verified)
                        }}
                        title={t('dcrPage.buttons.accept')}
                        color="secondary"
                        disabled={store.store.loading || !store.valid}
                    />
                    <SimpleButton
                        onClick={async () => await store.changeStatus(RequestStatusEnum.Partialy)}
                        title={t('dcrPage.statuses.partialy')}
                        disabled={store.store.loading || !store.valid}
                        variant="outlined"
                        style={{width: 'max-content'}}
                    />
                </>
            }
            {
                store.store.isTake &&
                <SimpleButton
                    onClick={async () => await store.changeStatus(RequestStatusEnum.Decline)}
                    title={t('dcrPage.buttons.decline')}
                    disabled={!store.store.commentStore.valid || store.store.loading}
                    color="error"
                    variant="outlined"
                />
            }
        </>

    );
});


export const AzDetailsAfterComponent = observer(({store}: Props, disabledstate: boolean) => {
    const {t} = useTranslation();

    const styleUl: React.CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '50%',
        maxHeight: '220px',
        flexDirection: "column",
        paddingLeft: '50px',
        marginTop: 'unset'
    }

    const styleLi: React.CSSProperties = {
        minWidth: 'unset',
        backgroundColor: 'transparent',
        lineHeight: 'unset',
    }

    return (
        <div style={{marginTop: 20}}>
            {
                !store.store._store.isEndStatus &&
                <div style={{display: 'flex'}}>
                    <DefaultValue store={store.store.commentStore}
                                  testid="azDetailsAfterComponent-commentStore"
                                  title={t("common.comment")}
                                  multiline
                                  disabled={store.store.commentStore.disabled}
                                  style={{height: '100%', width: '44%'}}
                                  autoFocus
                                  InputProps={{
                                      sx: {
                                          height: '100%'
                                      }
                                  }}
                                  rows={8}/>
                    <ul style={styleUl} >
                        {store.commentField.map((x, index) =>
                            <li key={index}>
                                <Button variant="text"
                                        title={x?.name}
                                        size="small"
                                        disabled={store.store.commentStore.disabled}
                                        style={styleLi}
                                        onClick={() => store.addTextField(x)}
                                >
                                    {x.name.trim()}
                                </Button>
                            </li>)}
                    </ul>
                </div>
            }
        </div>
    );
});
