/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    StringRequestFieldListKeyValuePair,
    StringRequestFieldListKeyValuePairFromJSON,
    StringRequestFieldListKeyValuePairFromJSONTyped,
    StringRequestFieldListKeyValuePairToJSON,
} from './StringRequestFieldListKeyValuePair';

/**
 * 
 * @export
 * @interface ResDto2
 */
export interface ResDto2 {
    /**
     * 
     * @type {StringRequestFieldListKeyValuePair}
     * @memberof ResDto2
     */
    entity?: StringRequestFieldListKeyValuePair;
    /**
     * 
     * @type {string}
     * @memberof ResDto2
     */
    erorType?: string | null;
}

export function ResDto2FromJSON(json: any): ResDto2 {
    return ResDto2FromJSONTyped(json, false);
}

export function ResDto2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ResDto2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': !exists(json, 'entity') ? undefined : StringRequestFieldListKeyValuePairFromJSON(json['entity']),
        'erorType': !exists(json, 'erorType') ? undefined : json['erorType'],
    };
}

export function ResDto2ToJSON(value?: ResDto2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': StringRequestFieldListKeyValuePairToJSON(value.entity),
        'erorType': value.erorType,
    };
}

