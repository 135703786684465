/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DcrGroupDto,
    DcrGroupDtoFromJSON,
    DcrGroupDtoToJSON,
    DcrGroupListDto,
    DcrGroupListDtoFromJSON,
    DcrGroupListDtoToJSON,
    DcrGroupListDtoPagedResponse,
    DcrGroupListDtoPagedResponseFromJSON,
    DcrGroupListDtoPagedResponseToJSON,
    DcrGroupPermissionDto,
    DcrGroupPermissionDtoFromJSON,
    DcrGroupPermissionDtoToJSON,
    DcrGroupPermissionDtoPagedResponse,
    DcrGroupPermissionDtoPagedResponseFromJSON,
    DcrGroupPermissionDtoPagedResponseToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetDcrGroupDto,
    SetDcrGroupDtoFromJSON,
    SetDcrGroupDtoToJSON,
} from '../models';

export interface CreateGroupRequest {
    setDcrGroupDto?: SetDcrGroupDto;
}

export interface DeleteGroupRequest {
    id?: string;
}

export interface GetGroupRequest {
    groupId: string;
}

export interface GetGroupsRequest {
    userId?: string;
    search?: string;
    page?: number;
    size?: number;
}

export interface GetPermissionsRequest {
    groupId: string;
    page?: number;
    size?: number;
}

export interface UpdateGroupRequest {
    dcrGroupDto: DcrGroupDto;
}

export interface UpdatePermissionRequest {
    id: string;
    dcrGroupPermissionDto: DcrGroupPermissionDto;
}

/**
 * 
 */
export class GroupApi extends runtime.BaseAPI {

    /**
     */
    async createGroupRaw(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DcrGroupListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDcrGroupDtoToJSON(requestParameters.setDcrGroupDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DcrGroupListDtoFromJSON(jsonValue));
    }

    /**
     */
    async createGroup(requestParameters: CreateGroupRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DcrGroupListDto> {
        const response = await this.createGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteGroupRaw(requestParameters: DeleteGroupRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/groups`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteGroup(requestParameters: DeleteGroupRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.deleteGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGroupRaw(requestParameters: GetGroupRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DcrGroupDto>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling getGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/groups/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DcrGroupDtoFromJSON(jsonValue));
    }

    /**
     */
    async getGroup(requestParameters: GetGroupRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DcrGroupDto> {
        const response = await this.getGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGroupsRaw(requestParameters: GetGroupsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DcrGroupListDtoPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DcrGroupListDtoPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async getGroups(requestParameters: GetGroupsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DcrGroupListDtoPagedResponse> {
        const response = await this.getGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPermissionsRaw(requestParameters: GetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DcrGroupPermissionDtoPagedResponse>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling getPermissions.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/groups/Permissions/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DcrGroupPermissionDtoPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async getPermissions(requestParameters: GetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DcrGroupPermissionDtoPagedResponse> {
        const response = await this.getPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateGroupRaw(requestParameters: UpdateGroupRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DcrGroupDto>> {
        if (requestParameters.dcrGroupDto === null || requestParameters.dcrGroupDto === undefined) {
            throw new runtime.RequiredError('dcrGroupDto','Required parameter requestParameters.dcrGroupDto was null or undefined when calling updateGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/groups`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DcrGroupDtoToJSON(requestParameters.dcrGroupDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DcrGroupDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateGroup(requestParameters: UpdateGroupRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DcrGroupDto> {
        const response = await this.updateGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePermissionRaw(requestParameters: UpdatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DcrGroupPermissionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePermission.');
        }

        if (requestParameters.dcrGroupPermissionDto === null || requestParameters.dcrGroupPermissionDto === undefined) {
            throw new runtime.RequiredError('dcrGroupPermissionDto','Required parameter requestParameters.dcrGroupPermissionDto was null or undefined when calling updatePermission.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/groups/permission`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DcrGroupPermissionDtoToJSON(requestParameters.dcrGroupPermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DcrGroupPermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePermission(requestParameters: UpdatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DcrGroupPermissionDto> {
        const response = await this.updatePermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
