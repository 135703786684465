/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface ResOutputDto
 */
export interface ResOutputDto {
    /**
     * 
     * @type {string}
     * @memberof ResOutputDto
     */
    clientName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResOutputDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResOutputDto
     */
    crmId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResOutputDto
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResOutputDto
     */
    statusVerification?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResOutputDto
     */
    dcrId?: string | null;
}

export function ResOutputDtoFromJSON(json: any): ResOutputDto {
    return ResOutputDtoFromJSONTyped(json, false);
}

export function ResOutputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResOutputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientName': !exists(json, 'clientName') ? undefined : json['clientName'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'crmId': !exists(json, 'crmId') ? undefined : json['crmId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusVerification': !exists(json, 'statusVerification') ? undefined : json['statusVerification'],
        'dcrId': !exists(json, 'dcrId') ? undefined : json['dcrId'],
    };
}

export function ResOutputDtoToJSON(value?: ResOutputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientName': value.clientName,
        'type': value.type,
        'crmId': value.crmId,
        'status': value.status,
        'statusVerification': value.statusVerification,
        'dcrId': value.dcrId,
    };
}

