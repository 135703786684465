/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from './Client';
import {
    Comment,
    CommentFromJSON,
    CommentFromJSONTyped,
    CommentToJSON,
} from './Comment';
import {
    Data360Update,
    Data360UpdateFromJSON,
    Data360UpdateFromJSONTyped,
    Data360UpdateToJSON,
} from './Data360Update';
import {
    DcrStatus,
    DcrStatusFromJSON,
    DcrStatusFromJSONTyped,
    DcrStatusToJSON,
} from './DcrStatus';
import {
    RequestField,
    RequestFieldFromJSON,
    RequestFieldFromJSONTyped,
    RequestFieldToJSON,
} from './RequestField';
import {
    RequestRecordType,
    RequestRecordTypeFromJSON,
    RequestRecordTypeFromJSONTyped,
    RequestRecordTypeToJSON,
} from './RequestRecordType';
import {
    RequestStatus,
    RequestStatusFromJSON,
    RequestStatusFromJSONTyped,
    RequestStatusToJSON,
} from './RequestStatus';
import {
    SourceType,
    SourceTypeFromJSON,
    SourceTypeFromJSONTyped,
    SourceTypeToJSON,
} from './SourceType';

/**
 * 
 * @export
 * @interface Request
 */
export interface Request {
    /**
     * 
     * @type {Date}
     * @memberof Request
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Request
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Request
     */
    creationDateInData360?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Request
     */
    inWorkDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    crmId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    crmRequestId?: number;
    /**
     * 
     * @type {RequestStatus}
     * @memberof Request
     */
    status?: RequestStatus;
    /**
     * 
     * @type {DcrStatus}
     * @memberof Request
     */
    dcrStatus?: DcrStatus;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    responsibleId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    originalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    morionId?: string | null;
    /**
     * 
     * @type {RequestRecordType}
     * @memberof Request
     */
    type?: RequestRecordType;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    data360Id?: string | null;
    /**
     * 
     * @type {Array<RequestField>}
     * @memberof Request
     */
    fields?: Array<RequestField> | null;
    /**
     * 
     * @type {Array<RequestField>}
     * @memberof Request
     */
    fieldsFirstValue?: Array<RequestField> | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Request
     */
    additionalFields?: { [key: string]: string; } | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Request
     */
    additionalFieldsFirstValue?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Data360Update}
     * @memberof Request
     */
    data360Update?: Data360Update;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    hash?: string | null;
    /**
     * 
     * @type {Client}
     * @memberof Request
     */
    client?: Client;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    groupId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Request
     */
    isErrorRequest?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Request
     */
    isWarningRequest?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Request
     */
    isMissed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    entityRequestCount?: number;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof Request
     */
    comments?: Array<Comment> | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    jurisdictionId?: string | null;
    /**
     *
     * @type {SourceType}
     * @memberof Request
     */
    sourceType?: SourceType;
}

export function RequestFromJSON(json: any): Request {
    return RequestFromJSONTyped(json, false);
}

export function RequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): Request {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'creationDateInData360': !exists(json, 'creationDateInData360') ? undefined : (new Date(json['creationDateInData360'])),
        'inWorkDate': !exists(json, 'inWorkDate') ? undefined : (json['inWorkDate'] === null ? null : new Date(json['inWorkDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'crmId': !exists(json, 'crmId') ? undefined : json['crmId'],
        'crmRequestId': !exists(json, 'crmRequestId') ? undefined : json['crmRequestId'],
        'status': !exists(json, 'status') ? undefined : RequestStatusFromJSON(json['status']),
        'dcrStatus': !exists(json, 'dcrStatus') ? undefined : DcrStatusFromJSON(json['dcrStatus']),
        'responsibleId': !exists(json, 'responsibleId') ? undefined : json['responsibleId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'originalId': !exists(json, 'originalId') ? undefined : json['originalId'],
        'morionId': !exists(json, 'morionId') ? undefined : json['morionId'],
        'type': !exists(json, 'type') ? undefined : RequestRecordTypeFromJSON(json['type']),
        'data360Id': !exists(json, 'data360Id') ? undefined : json['data360Id'],
        'fields': !exists(json, 'fields') ? undefined : (json['fields'] === null ? null : (json['fields'] as Array<any>).map(RequestFieldFromJSON)),
        'fieldsFirstValue': !exists(json, 'fieldsFirstValue') ? undefined : (json['fieldsFirstValue'] === null ? null : (json['fieldsFirstValue'] as Array<any>).map(RequestFieldFromJSON)),
        'additionalFields': !exists(json, 'additionalFields') ? undefined : json['additionalFields'],
        'additionalFieldsFirstValue': !exists(json, 'additionalFieldsFirstValue') ? undefined : json['additionalFieldsFirstValue'],
        'data360Update': !exists(json, 'data360Update') ? undefined : Data360UpdateFromJSON(json['data360Update']),
        'hash': !exists(json, 'hash') ? undefined : json['hash'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'isErrorRequest': !exists(json, 'isErrorRequest') ? undefined : json['isErrorRequest'],
        'isWarningRequest': !exists(json, 'isWarningRequest') ? undefined : json['isWarningRequest'],
        'isMissed': !exists(json, 'isMissed') ? undefined : json['isMissed'],
        'entityRequestCount': !exists(json, 'entityRequestCount') ? undefined : json['entityRequestCount'],
        'comments': !exists(json, 'comments') ? undefined : (json['comments'] === null ? null : (json['comments'] as Array<any>).map(CommentFromJSON)),
        'jurisdictionId': !exists(json, 'jurisdictionId') ? undefined : json['jurisdictionId'],
        'sourceType': !exists(json, 'sourceType') ? undefined : SourceTypeFromJSON(json['sourceType']),
    };
}

export function RequestToJSON(value?: Request | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'creationDateInData360': value.creationDateInData360 === undefined ? undefined : (value.creationDateInData360.toISOString()),
        'inWorkDate': value.inWorkDate === undefined ? undefined : (value.inWorkDate === null ? null : value.inWorkDate.toISOString()),
        'id': value.id,
        'crmId': value.crmId,
        'crmRequestId': value.crmRequestId,
        'status': RequestStatusToJSON(value.status),
        'dcrStatus': DcrStatusToJSON(value.dcrStatus),
        'responsibleId': value.responsibleId,
        'comment': value.comment,
        'originalId': value.originalId,
        'morionId': value.morionId,
        'type': RequestRecordTypeToJSON(value.type),
        'data360Id': value.data360Id,
        'fields': value.fields === undefined ? undefined : (value.fields === null ? null : (value.fields as Array<any>).map(RequestFieldToJSON)),
        'fieldsFirstValue': value.fieldsFirstValue === undefined ? undefined : (value.fieldsFirstValue === null ? null : (value.fieldsFirstValue as Array<any>).map(RequestFieldToJSON)),
        'additionalFields': value.additionalFields,
        'additionalFieldsFirstValue': value.additionalFieldsFirstValue,
        'data360Update': Data360UpdateToJSON(value.data360Update),
        'hash': value.hash,
        'client': ClientToJSON(value.client),
        'groupId': value.groupId,
        'isErrorRequest': value.isErrorRequest,
        'isWarningRequest': value.isWarningRequest,
        'isMissed': value.isMissed,
        'entityRequestCount': value.entityRequestCount,
        'comments': value.comments === undefined ? undefined : (value.comments === null ? null : (value.comments as Array<any>).map(CommentToJSON)),
        'jurisdictionId': value.jurisdictionId,
        'sourceType': SourceTypeToJSON(value.sourceType),
    };
}

