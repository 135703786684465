/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    RequestField,
    RequestFieldFromJSON,
    RequestFieldFromJSONTyped,
    RequestFieldToJSON,
} from './RequestField';

/**
 * 
 * @export
 * @interface StringRequestFieldListKeyValuePair
 */
export interface StringRequestFieldListKeyValuePair {
    /**
     * 
     * @type {string}
     * @memberof StringRequestFieldListKeyValuePair
     */
    key?: string | null;
    /**
     * 
     * @type {Array<RequestField>}
     * @memberof StringRequestFieldListKeyValuePair
     */
    value?: Array<RequestField> | null;
}

export function StringRequestFieldListKeyValuePairFromJSON(json: any): StringRequestFieldListKeyValuePair {
    return StringRequestFieldListKeyValuePairFromJSONTyped(json, false);
}

export function StringRequestFieldListKeyValuePairFromJSONTyped(json: any, ignoreDiscriminator: boolean): StringRequestFieldListKeyValuePair {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : (json['value'] === null ? null : (json['value'] as Array<any>).map(RequestFieldFromJSON)),
    };
}

export function StringRequestFieldListKeyValuePairToJSON(value?: StringRequestFieldListKeyValuePair | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value === undefined ? undefined : (value.value === null ? null : (value.value as Array<any>).map(RequestFieldToJSON)),
    };
}

