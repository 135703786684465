/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';

/**
 *
 * @export
 * @interface BatchRequestsDeleteCommand
 */
export interface BatchRequestsDeleteCommand {
    /**
     *
     * @type {boolean}
     * @memberof BatchRequestsDeleteCommand
     */
    force?: boolean;
    /**
     *
     * @type {string}
     * @memberof BatchRequestsDeleteCommand
     */
    userId?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BatchRequestsDeleteCommand
     */
    requestIds?: Array<string> | null;
}

export function BatchRequestsDeleteCommandFromJSON(json: any): BatchRequestsDeleteCommand {
    return BatchRequestsDeleteCommandFromJSONTyped(json, false);
}

export function BatchRequestsDeleteCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchRequestsDeleteCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'force': !exists(json, 'force') ? undefined : json['force'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'requestIds': !exists(json, 'requestIds') ? undefined : json['requestIds'],
    };
}

export function BatchRequestsDeleteCommandToJSON(value?: BatchRequestsDeleteCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'force': value.force,
        'userId': value.userId,
        'requestIds': value.requestIds,
    };
}

