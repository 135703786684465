/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    ClientType,
    ClientTypeFromJSON,
    ClientTypeFromJSONTyped,
    ClientTypeToJSON,
} from './ClientType';
import {
    DcrStatus,
    DcrStatusFromJSON,
    DcrStatusFromJSONTyped,
    DcrStatusToJSON,
} from './DcrStatus';
import {
    RequestRecordType,
    RequestRecordTypeFromJSON,
    RequestRecordTypeFromJSONTyped,
    RequestRecordTypeToJSON,
} from './RequestRecordType';
import {
    RequestStatus,
    RequestStatusFromJSON,
    RequestStatusFromJSONTyped,
    RequestStatusToJSON,
} from './RequestStatus';

/**
 * 
 * @export
 * @interface RequestDto
 */
export interface RequestDto {
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof RequestDto
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RequestDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {RequestStatus}
     * @memberof RequestDto
     */
    status: RequestStatus;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    responsibleId: string;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    responsibleName?: string | null;
    /**
     * 
     * @type {RequestRecordType}
     * @memberof RequestDto
     */
    type: RequestRecordType;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    crmId: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RequestDto
     */
    additionalFields: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    externalId: string;
    /**
     * 
     * @type {DcrStatus}
     * @memberof RequestDto
     */
    dcrStatus?: DcrStatus;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    data360Id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    client: string;
    /**
     * 
     * @type {ClientType}
     * @memberof RequestDto
     */
    clientType?: ClientType;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    groupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    boundId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequestDto
     */
    isErrorRequest?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RequestDto
     */
    commentCount?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestDto
     */
    entityRequestCount?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    jurisdictionId?: string | null;
}

export function RequestDtoFromJSON(json: any): RequestDto {
    return RequestDtoFromJSONTyped(json, false);
}

export function RequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'status': RequestStatusFromJSON(json['status']),
        'responsibleId': json['responsibleId'],
        'responsibleName': !exists(json, 'responsibleName') ? undefined : json['responsibleName'],
        'type': RequestRecordTypeFromJSON(json['type']),
        'crmId': json['crmId'],
        'additionalFields': json['additionalFields'],
        'externalId': json['externalId'],
        'dcrStatus': !exists(json, 'dcrStatus') ? undefined : DcrStatusFromJSON(json['dcrStatus']),
        'data360Id': !exists(json, 'data360Id') ? undefined : json['data360Id'],
        'client': json['client'],
        'clientType': !exists(json, 'clientType') ? undefined : ClientTypeFromJSON(json['clientType']),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'boundId': !exists(json, 'boundId') ? undefined : json['boundId'],
        'isErrorRequest': !exists(json, 'isErrorRequest') ? undefined : json['isErrorRequest'],
        'commentCount': !exists(json, 'commentCount') ? undefined : json['commentCount'],
        'entityRequestCount': !exists(json, 'entityRequestCount') ? undefined : json['entityRequestCount'],
        'jurisdictionId': !exists(json, 'jurisdictionId') ? undefined : json['jurisdictionId'],
    };
}

export function RequestDtoToJSON(value?: RequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'status': RequestStatusToJSON(value.status),
        'responsibleId': value.responsibleId,
        'responsibleName': value.responsibleName,
        'type': RequestRecordTypeToJSON(value.type),
        'crmId': value.crmId,
        'additionalFields': value.additionalFields,
        'externalId': value.externalId,
        'dcrStatus': DcrStatusToJSON(value.dcrStatus),
        'data360Id': value.data360Id,
        'client': value.client,
        'clientType': ClientTypeToJSON(value.clientType),
        'comment': value.comment,
        'groupId': value.groupId,
        'boundId': value.boundId,
        'isErrorRequest': value.isErrorRequest,
        'commentCount': value.commentCount,
        'entityRequestCount': value.entityRequestCount,
        'jurisdictionId': value.jurisdictionId,
    };
}

