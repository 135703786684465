/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    DcrStatus,
    DcrStatusFromJSON,
    DcrStatusFromJSONTyped,
    DcrStatusToJSON,
} from './DcrStatus';
import {
    RequestRecordType,
    RequestRecordTypeFromJSON,
    RequestRecordTypeFromJSONTyped,
    RequestRecordTypeToJSON,
} from './RequestRecordType';
import {
    RequestStatus,
    RequestStatusFromJSON,
    RequestStatusFromJSONTyped,
    RequestStatusToJSON,
} from './RequestStatus';

/**
 * 
 * @export
 * @interface SubRequest
 */
export interface SubRequest {
    /**
     * 
     * @type {string}
     * @memberof SubRequest
     */
    id: string;
    /**
     * 
     * @type {RequestStatus}
     * @memberof SubRequest
     */
    status: RequestStatus;
    /**
     * 
     * @type {DcrStatus}
     * @memberof SubRequest
     */
    dcrStatus: DcrStatus;
    /**
     * 
     * @type {RequestRecordType}
     * @memberof SubRequest
     */
    type: RequestRecordType;
    /**
     * 
     * @type {Date}
     * @memberof SubRequest
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SubRequest
     */
    readonly date: string;
    /**
     * 
     * @type {string}
     * @memberof SubRequest
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubRequest
     */
    crmId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubRequest
     */
    escalationComment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubRequest
     */
    readonly isEscalation?: boolean;
}

export function SubRequestFromJSON(json: any): SubRequest {
    return SubRequestFromJSONTyped(json, false);
}

export function SubRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': RequestStatusFromJSON(json['status']),
        'dcrStatus': DcrStatusFromJSON(json['dcrStatus']),
        'type': RequestRecordTypeFromJSON(json['type']),
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
        'date': json['date'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'crmId': !exists(json, 'crmId') ? undefined : json['crmId'],
        'escalationComment': !exists(json, 'escalationComment') ? undefined : json['escalationComment'],
        'isEscalation': !exists(json, 'isEscalation') ? undefined : json['isEscalation'],
    };
}

export function SubRequestToJSON(value?: SubRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': RequestStatusToJSON(value.status),
        'dcrStatus': DcrStatusToJSON(value.dcrStatus),
        'type': RequestRecordTypeToJSON(value.type),
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
        'comment': value.comment,
        'crmId': value.crmId,
        'escalationComment': value.escalationComment,
    };
}

