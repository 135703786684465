import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SwapVerticalCircleTwoToneIcon from '@mui/icons-material/SwapVerticalCircleTwoTone';
import {Link, Table, TableBody, TableFooter, TableHead, TableRow, Tooltip, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from '@mui/material/Unstable_Grid2';
import {observer} from 'mobx-react';
import React, {Fragment, useEffect} from "react";
import Scrollbars from 'react-custom-scrollbars';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Cell, EmptyBlock2, HCell, ProgressCircleCenter} from "../../../../components";
import {Overview} from "../../../../components/card-templates/overview";
import {BarChart} from "../../../../components/charts/bar/BarChar";
import {Chart} from "../../../../components/charts/chart";
import {LineChart} from "../../../../components/charts/line/LineChart";
import {PipeChart, useChartOptions} from "../../../../components/charts/pipe/PipeChart";
import {useAreaChartOptions} from "../../../../components/charts/shared-settings";
import {ClientsIcon} from "../../../../components/icons/Clients";
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";
import {useSettings} from "../../../../core/hooks/use-settings";
import {EntityType, FeedStatus} from "../../../../services/analytics/exchange";
import {useManagementStore} from "../../../../stores";
import {AnalyticsPage} from "../AnalyticsPage";

export const ExchangeDashboards = observer(() => {
    const { t } = useTranslation();
    const { dashboardsPageStore } = useManagementStore();
    const navigate = useNavigate();
    const settings = useSettings();

    const store = dashboardsPageStore.exchangeDashboardsStore;

    const areaChartOptions = useAreaChartOptions();
    const pipePersonChartOptions = useChartOptions(store.personsPipeData.map(x => x.title), store.personsPipeData.map(x => x.count));
    const pipeOrganizationPipeOptions = useChartOptions(store.organizationsPipeData.map(x => x.title), store.organizationsPipeData.map(x => x.count));

    const handleReportNavigate = (prop: { status?: FeedStatus[], entityType?: EntityType[], client?: string, orgType?: string, speciality?: string }) => {
        store.saveReportFilters(prop)
        navigate('/analytics/exchange/report')
    }

    useEffect(() => {
        const timer = setInterval(() => {
            store.updateState()
        }, 60000);
        return () => clearInterval(timer);
    }, []);

    return (
        <AnalyticsPage store={store.dateFilters} title={t("pageNames.exchange")}>
            <ProgressCircleCenter store={store}/>

            <>
                <Grid xs={12} md={4}>
                    <Overview amount={store.clientsCount}
                        onClickDetails={() => handleReportNavigate({})}
                        icon={<ClientsIcon />}
                        title={t('analytics.exchange.overviews.clients')} />
                </Grid>
                <Grid xs={12} md={4}>
                    <Overview amount={store.errorsCount}
                        onClickDetails={() => handleReportNavigate({ status: ["Failed"] })}
                        style={{ padding: '0 20px' }}
                        icon={<ErrorTwoToneIcon style={{ fontSize: '3rem' }} color="error" />}
                        title={t('analytics.exchange.overviews.errorsCount')}>
                        <Chart
                            height={100}
                            options={areaChartOptions}
                            series={[{ data: store.errorsData.map(x => x.count ?? 0) }]}
                            type="area"
                        />
                    </Overview>
                </Grid>
                <Grid xs={12} md={4}>
                    <Overview amount={store.exchangeCount}
                        onClickDetails={() => handleReportNavigate({})}
                        style={{ padding: '0 20px' }}
                        icon={<SwapVerticalCircleTwoToneIcon style={{ fontSize: '3rem' }}
                            color="success" />}
                        title={t('analytics.exchange.overviews.totalExchange')}>
                        <Chart
                            height={100}
                            options={areaChartOptions}
                            series={[{ data: store.exchangeData.map(x => x.count ?? 0) }]}
                            type="area"
                        />
                    </Overview>
                </Grid>
                <Grid xs={12} md={6}>
                    <Card>
                        <CardHeader title={
                            <Link onClick={(e) => handleReportNavigate({ entityType: ['Person'] })}
                                style={{ cursor: 'pointer', fontWeight: 600 }}>
                                {t("analytics.exchange.pipeCharts.persons")}
                            </Link>
                        }
                            action={(
                                <Tooltip
                                    title={t("analytics.exchange.pipeCharts.personsDescription")}>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            )}
                            style={{ paddingbottom: -1 }} />
                        <CardContent>
                            <PipeChart apexOptions={pipePersonChartOptions} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardHeader title={
                            <Link onClick={(e) => handleReportNavigate({ entityType: ['Organization'] })}
                                style={{ cursor: 'pointer', fontWeight: 600 }}>
                                {t("analytics.exchange.pipeCharts.organizations")}
                            </Link>
                        }
                            action={(
                                <Tooltip
                                    title={t("analytics.exchange.pipeCharts.organizationsDescription")}>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            )}
                            style={{ paddingbottom: -1 }} />
                        <CardContent>
                            <PipeChart apexOptions={pipeOrganizationPipeOptions} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={12}>
                    <DefaultSelect store={store.client}
                        title={t('dcrPage.requestTable.client')}
                                   nameExp="name"
                                   valueExp="id"
                        style={{ width: '32%' }}
                    />
                </Grid>
                <Grid xs={12} md={12}>
                    <Card>
                        <CardHeader
                            style={{ paddingbottom: -1 }}
                            title={
                                <Link
                                    onClick={(e) => handleReportNavigate({
                                        entityType: ['Organization', 'Person'],
                                        client: store.client.value?.id
                                    })}
                                    style={{ cursor: 'pointer', fontWeight: 600 }}>
                                    {t("analytics.exchange.summaryChart.title")}
                                </Link>
                            }
                            action={
                                <Tooltip title={t("analytics.exchange.summaryChart.action")}>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            }
                        />
                        <CardContent>
                            <LineChart data={store.summaryExchange} periods={store.dateFilters.categoriesLabel} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={6}>
                    <Card>
                        <CardHeader
                            style={{ paddingbottom: -1 }}
                            title={
                                <Link
                                    onClick={(e) => handleReportNavigate({
                                        entityType: ['Person'],
                                        status: ["Failed"],
                                        client: store.client.value?.id
                                    })}
                                    style={{ cursor: 'pointer', fontWeight: 600 }}>
                                    {t("analytics.exchange.personsChart.title")}
                                </Link>
                            }
                            action={
                                <div style={{ display: 'flex' }}>
                                    <Tooltip title={t("analytics.exchange.personsChart.action")}>
                                        <InfoOutlinedIcon />
                                    </Tooltip>
                                </div>

                            }
                        />
                        <CardContent>
                            <BarChart data={store.personsErrorsBarData}
                                periods={store.dateFilters.categoriesLabel} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={6}>
                    <Card>
                        <CardHeader
                            style={{ paddingbottom: -1 }}
                            title={
                                <Link
                                    onClick={(e) => handleReportNavigate({
                                        entityType: ['Organization'],
                                        status: ["Failed"],
                                        client: store.client.value?.id
                                    })}
                                    style={{ cursor: 'pointer', fontWeight: 600 }}>
                                    {t("analytics.exchange.organizationsChart.title")}
                                </Link>
                            }
                            action={
                                <div style={{ display: 'flex' }}>
                                    <Tooltip title={t("analytics.exchange.organizationsChart.action")}>
                                        <InfoOutlinedIcon />
                                    </Tooltip>
                                </div>
                            }
                        />
                        <CardContent>
                            <BarChart data={store.organizationsErrorsBarData}
                                periods={store.dateFilters.categoriesLabel} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid xs={12} md={6}>
                    <Card>
                        <CardHeader
                            action={(
                                <Tooltip title={t("analytics.exchange.specialityTable.action")}>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            )}
                            title={t("analytics.exchange.specialityTable.title")}
                            style={{ paddingbottom: -1 }}
                        />
                        <CardContent>
                            <Scrollbars style={{ height: 415 }} autoHide>
                                <Table style={{ borderCollapse: 'separate' }}>
                                    <TableHead style={{ position: "sticky", top: 0 }}>
                                        <TableRow>
                                            <HCell style={{ padding: '6px 30px' }}>{t('analytics.exchange.specialityTable.columns.name')}</HCell>
                                            <HCell styleText={{ marginLeft: 'auto', paddingRight: 16 }}>{t('analytics.exchange.specialityTable.columns.count')}</HCell>
                                            <HCell styleText={{ marginLeft: 'auto', paddingRight: 16 }}>{t('analytics.exchange.specialityTable.columns.errors')}</HCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            store.specialityData.map(i => {
                                                return <Fragment key={i.specialityId}>
                                                    <TableRow
                                                        hover
                                                        key={i.specialityId}
                                                    >
                                                        <Cell style={{ padding: '15px 30px' }}>
                                                            <Typography variant="subtitle2">
                                                                {i.specialityName}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell align='right'>
                                                            <Link
                                                                onClick={(e) => handleReportNavigate({
                                                                    entityType: ['Person'],
                                                                    speciality: i.specialityId ?? undefined,
                                                                    client: store.client.value?.id,
                                                                    status: ["Succeeded"]
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: 600
                                                                }}>
                                                                {i.succeededCount}
                                                            </Link>
                                                        </Cell>
                                                        <Cell align='right'>
                                                            <Link
                                                                onClick={(e) => handleReportNavigate({
                                                                    entityType: ['Person'],
                                                                    speciality: i.specialityId ?? undefined,
                                                                    client: store.client.value?.id,
                                                                    status: ["Failed"]
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: 600
                                                                }}>
                                                                {i.failedCount}
                                                            </Link>
                                                        </Cell>
                                                    </TableRow>
                                                </Fragment>
                                            })
                                        }
                                        {
                                            store.specialityData.length === 0 &&
                                            <EmptyBlock2 />
                                        }
                                    </TableBody>
                                    {
                                        store.specialityData.length > 0 &&
                                        <TableFooter
                                            style={{
                                                position: 'sticky',
                                                bottom: -1,
                                                backgroundColor: settings.paletteMode === 'dark' ? '#1F2937' : '#F8F9FA'
                                            }}>
                                            <Fragment key='speciality-total'>
                                                <TableRow
                                                    hover
                                                    key='speciality-total'
                                                >
                                                    <Cell style={{ padding: '15px 30px' }}>
                                                        <Typography variant="subtitle2">
                                                            {t("common.allCount")}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell width="100" align='right'>
                                                        <Typography variant="subtitle2">
                                                            {store.specialityData.length > 0 ? store.specialityData.map(x => x.succeededCount ?? 0).reduce((x, y) => x + y) : 0}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell width="100" align='right'>
                                                        <Typography variant="subtitle2">
                                                            {store.specialityData.length > 0 ? store.specialityData.map(x => x.failedCount ?? 0).reduce((x, y) => x + y) : 0}
                                                        </Typography>
                                                    </Cell>
                                                </TableRow>
                                            </Fragment>
                                        </TableFooter>
                                    }

                                </Table>
                            </Scrollbars>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={6}>
                    <Card>
                        <CardHeader
                            action={(
                                <Tooltip title={t("analytics.exchange.orgTypeTable.action")}>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            )}
                            title={t("analytics.exchange.orgTypeTable.title")}
                            style={{ paddingbottom: -1 }}
                        />
                        <CardContent>
                            <Scrollbars style={{ height: 415 }} autoHide>
                                <Table style={{ borderCollapse: 'separate' }}>
                                    <TableHead style={{ position: "sticky", top: 0 }}>
                                        <TableRow>
                                            <HCell style={{ padding: '6px 30px' }}>{t('analytics.exchange.orgTypeTable.columns.name')}</HCell>
                                            <HCell styleText={{ marginLeft: 'auto', paddingRight: 16 }}>{t('analytics.exchange.orgTypeTable.columns.count')}</HCell>
                                            <HCell styleText={{ marginLeft: 'auto', paddingRight: 16 }}>{t('analytics.exchange.orgTypeTable.columns.errors')}</HCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            store.orgTypeData.map(i => {
                                                return <Fragment key={i.organizationTypeId}>
                                                    <TableRow
                                                        hover
                                                        key={i.organizationTypeId}
                                                    >
                                                        <Cell style={{ padding: '15px 30px' }}>
                                                            <Typography variant="subtitle2">
                                                                {i.organizationTypeName}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell align='right'>
                                                            <Link
                                                                onClick={(e) => handleReportNavigate({
                                                                    entityType: ['Organization'],
                                                                    orgType: i.organizationTypeId ?? undefined,
                                                                    client: store.client.value?.id,
                                                                    status: ["Succeeded"]
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: 600
                                                                }}>
                                                                {i.succeededCount}
                                                            </Link>
                                                        </Cell>
                                                        <Cell align='right'>
                                                            <Link
                                                                onClick={(e) => handleReportNavigate({
                                                                    entityType: ['Organization'],
                                                                    orgType: i.organizationTypeId ?? undefined,
                                                                    client: store.client.value?.id,
                                                                    status: ["Failed"]
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: 600
                                                                }}>
                                                                {i.failedCount}
                                                            </Link>
                                                        </Cell>
                                                    </TableRow>
                                                </Fragment>
                                            })
                                        }
                                        {
                                            store.orgTypeData.length == 0 &&
                                            <EmptyBlock2 />
                                        }
                                    </TableBody>
                                    {
                                        store.orgTypeData.length > 0 &&
                                        <TableFooter
                                            style={{
                                                position: 'sticky',
                                                bottom: -1,
                                                backgroundColor: settings.paletteMode == 'dark' ? '#1F2937' : '#F8F9FA'
                                            }}>
                                            <Fragment key='organizationType-total'>
                                                <TableRow
                                                    hover
                                                    key='organizationType-total'
                                                >
                                                    <Cell style={{ padding: '15px 30px' }}>
                                                        <Typography variant="subtitle2">
                                                            {t("common.allCount")}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell width="100" align='right'>
                                                        <Typography variant="subtitle2">
                                                            {store.orgTypeData.length > 0 ? store.orgTypeData.map(x => x.succeededCount ?? 0).reduce((x, y) => x + y) : 0}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell width="100" align='right'>
                                                        <Typography variant="subtitle2">
                                                            {store.orgTypeData.length > 0 ? store.orgTypeData.map(x => x.failedCount ?? 0).reduce((x, y) => x + y) : 0}
                                                        </Typography>
                                                    </Cell>
                                                </TableRow>
                                            </Fragment>
                                        </TableFooter>
                                    }

                                </Table>
                            </Scrollbars>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={12}>
                    <Card>
                        <CardHeader
                            style={{ paddingbottom: -1 }}
                            action={(
                                <Tooltip title={t("analytics.exchange.clientsTable.action")}>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            )}
                            title={t("analytics.exchange.clientsTable.title")}
                        />
                        <CardContent>
                            <Scrollbars style={{ height: 400, }} autoHide>
                                <Table style={{ borderCollapse: 'separate' }}>
                                    <TableHead style={{ position: "sticky", top: 0 }}>
                                        <TableRow>
                                            <HCell style={{ padding: '15px 30px' }}>{t('analytics.exchange.clientsTable.columns.client')}</HCell>
                                            <HCell styleText={{ marginLeft: 'auto', paddingRight: 16 }}>{t('analytics.exchange.clientsTable.columns.persons')}
                                                <Tooltip style={{ paddingLeft: 10 }} title={t("analytics.exchange.clientsTable.columns.action")}>
                                                    <InfoOutlinedIcon fontSize="medium" style={{marginBottom: '-7px', paddingLeft:' 6px'}}/>
                                                </Tooltip>
                                            </HCell>
                                            <HCell styleText={{ marginLeft: 'auto', paddingRight: 16 }}>{t('analytics.exchange.clientsTable.columns.organizations')}
                                                <Tooltip style={{ paddingLeft: 10 }} title={t("analytics.exchange.clientsTable.columns.action")}>
                                                    <InfoOutlinedIcon fontSize="medium" style={{marginBottom: '-7px', paddingLeft:' 6px'}}/>
                                                </Tooltip>
                                            </HCell>
                                            <HCell styleText={{ marginLeft: 'auto', paddingRight: 16 }}>{t('analytics.exchange.clientsTable.columns.all')}
                                                <Tooltip style={{ paddingLeft: 10 }} title={t("analytics.exchange.clientsTable.columns.action")}>
                                                    <InfoOutlinedIcon fontSize="medium" style={{marginBottom: '-7px', paddingLeft:' 6px'}} />
                                                </Tooltip>
                                            </HCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            store.summaryAggregationData.map(i => {
                                                return <Fragment key={i.clientId}>
                                                    <TableRow
                                                        hover
                                                        key={i.clientId}
                                                    >
                                                        <Cell style={{ padding: '15px 30px' }}>
                                                            <Typography variant="subtitle2">
                                                                {i.clientName}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell align='right'>
                                                            <Link
                                                                onClick={(e) => handleReportNavigate({
                                                                    client: i.clientId!,
                                                                    entityType: ['Person']
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: 600
                                                                }}>
                                                                {i.persons?.succeededCount ?? 0} / {i.persons?.failedCount ?? 0}
                                                            </Link>
                                                        </Cell>
                                                        <Cell align='right'>
                                                            <Link
                                                                onClick={(e) => handleReportNavigate({
                                                                    client: i.clientId!,
                                                                    entityType: ['Organization']
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: 600
                                                                }}>
                                                                {i.organizations?.succeededCount ?? 0} / {i.organizations?.failedCount ?? 0}
                                                            </Link>
                                                        </Cell>
                                                        <Cell align='right'>
                                                            <Link
                                                                onClick={(e) => handleReportNavigate({
                                                                    client: i.clientId!,
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: 600
                                                                }}>
                                                                {i.total?.succeededCount ?? 0} / {i.total?.failedCount ?? 0}
                                                            </Link>
                                                            <Typography variant="subtitle2">
                                                            </Typography>
                                                        </Cell>
                                                    </TableRow>
                                                </Fragment>
                                            })
                                        }
                                        {
                                            store.summaryAggregationData.length == 0 &&
                                            <EmptyBlock2 />
                                        }
                                    </TableBody>
                                    {
                                        store.summaryAggregationData.length > 0 &&
                                        <TableFooter
                                            style={{
                                                position: 'sticky',
                                                bottom: -1,
                                                backgroundColor: settings.paletteMode == 'dark' ? '#1F2937' : '#F8F9FA'
                                            }}>
                                            <Fragment key='summaryAggregationData-total'>
                                                <TableRow
                                                    hover
                                                    key='summaryAggregationData-total'
                                                >
                                                    <Cell style={{ padding: '15px 30px' }}>
                                                        <Typography variant="subtitle2">
                                                            {t("common.allCount")}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell align='right'>
                                                        <Typography variant="subtitle2">
                                                            {store.summaryAggregationData.length > 0 ?
                                                                (store.summaryAggregationData.map(x => x.persons?.succeededCount ?? 0).reduce((x, y) => x + y) + ' / '
                                                                    + store.summaryAggregationData.map(x => x.persons?.failedCount ?? 0).reduce((x, y) => x + y))
                                                                : (0 / 0)}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell align='right'>
                                                        <Typography variant="subtitle2">
                                                            {store.summaryAggregationData.length > 0 ?
                                                                (store.summaryAggregationData.map(x => x.organizations?.succeededCount ?? 0).reduce((x, y) => x + y) + ' / '
                                                                    + store.summaryAggregationData.map(x => x.organizations?.failedCount ?? 0).reduce((x, y) => x + y))
                                                                : ('0 / 0')}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell align='right'>
                                                        <Typography variant="subtitle2">
                                                            {store.summaryAggregationData.length > 0 ?
                                                                (store.summaryAggregationData.map(x => x.total?.succeededCount ?? 0).reduce((x, y) => x + y) + ' / '
                                                                    + store.summaryAggregationData.map(x => x.total?.failedCount ?? 0).reduce((x, y) => x + y))
                                                                : ('0 / 0')}
                                                        </Typography>
                                                    </Cell>
                                                </TableRow>
                                            </Fragment>
                                        </TableFooter>
                                    }
                                </Table>
                            </Scrollbars>
                        </CardContent>
                    </Card>
                </Grid>
            </>
        </AnalyticsPage>
    );
});
