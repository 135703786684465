/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    FieldInfoDto,
    FieldInfoDtoFromJSON,
    FieldInfoDtoFromJSONTyped,
    FieldInfoDtoToJSON,
} from './FieldInfoDto';
import {
    FirstValueDto,
    FirstValueDtoFromJSON,
    FirstValueDtoFromJSONTyped,
    FirstValueDtoToJSON,
} from './FirstValueDto';
import {
    SubRequest,
    SubRequestFromJSON,
    SubRequestFromJSONTyped,
    SubRequestToJSON,
} from './SubRequest';

/**
 * 
 * @export
 * @interface RequestDetailsModel
 */
export interface RequestDetailsModel {
    /**
     * 
     * @type {Array<FieldInfoDto>}
     * @memberof RequestDetailsModel
     */
    fields?: Array<FieldInfoDto> | null;
    /**
     * 
     * @type {Array<SubRequest>}
     * @memberof RequestDetailsModel
     */
    subRequests?: Array<SubRequest> | null;
    /**
     * 
     * @type {Array<FirstValueDto>}
     * @memberof RequestDetailsModel
     */
    firstValues?: Array<FirstValueDto> | null;
}

export function RequestDetailsModelFromJSON(json: any): RequestDetailsModel {
    return RequestDetailsModelFromJSONTyped(json, false);
}

export function RequestDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fields': !exists(json, 'fields') ? undefined : (json['fields'] === null ? null : (json['fields'] as Array<any>).map(FieldInfoDtoFromJSON)),
        'subRequests': !exists(json, 'subRequests') ? undefined : (json['subRequests'] === null ? null : (json['subRequests'] as Array<any>).map(SubRequestFromJSON)),
        'firstValues': !exists(json, 'firstValues') ? undefined : (json['firstValues'] === null ? null : (json['firstValues'] as Array<any>).map(FirstValueDtoFromJSON)),
    };
}

export function RequestDetailsModelToJSON(value?: RequestDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fields': value.fields === undefined ? undefined : (value.fields === null ? null : (value.fields as Array<any>).map(FieldInfoDtoToJSON)),
        'subRequests': value.subRequests === undefined ? undefined : (value.subRequests === null ? null : (value.subRequests as Array<any>).map(SubRequestToJSON)),
        'firstValues': value.firstValues === undefined ? undefined : (value.firstValues === null ? null : (value.firstValues as Array<any>).map(FirstValueDtoToJSON)),
    };
}

