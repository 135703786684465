import {computed, makeObservable, observable} from 'mobx';
import {getApiConfiguration, IProcessing} from "../../../../../../../core";
import {
    ClientLocalitiesApi,
    GetBoundLocalitiesRequest,
    GetLocalitiesRequest,
    GetTypeOrgsRequest,
    LocalitiesApi,
    LocalityDto,
    QueryType
} from "../../../../../../../services/management";
import {LocalityItemStore} from "./LocalityItemStore";
import {BoundsStore} from "../BoundsStore";
import {ClientFragment} from "../../../../../../../gql/graphql";
import {ItemsLoadStore} from "../../../../../../utils";
import {sort} from "../../../../../../../core/sort";
import {DefaultValueStore} from "../../../../../../../components/shared/DefaultValueStore";


export class LocalityTableStore extends ItemsLoadStore<LocalityItemStore> implements IProcessing {

    readonly store: BoundsStore;
    readonly _dto: ClientFragment;
    public emptyId: string | undefined;
    public initLocality: LocalityDto[] = [];
    processing: boolean = false;
    public selectedLocalityId: string[] = [];
    public search: DefaultValueStore<string> = new DefaultValueStore<string>("",
        (x) => x && x?.length < 3 ? "Минимум 3 символа" : "", async x => {
            if (this.search.valid)
                await this.updateState()
        })

    constructor(store: BoundsStore, dto: ClientFragment) {
        super();
        this.store = store;
        this._dto = dto;
        makeObservable(this, {
            processing: observable,
            emptyId: observable,
            selectedLocalityId: observable,
            initLocality: observable,
            newSelectedItems: computed,
            newUnSelectedItems: computed,
            equals: computed,
            selectedLocalityIds: computed,
            selectedExpandedIds: computed,
            allLocality: computed,
        });
    }

    get newSelectedItems() {
        const items = this.selectedLocalityIds.filter(x => this.selectedLocalityId.indexOf(x) == -1);

        return items.filter(x => this.allLocality.map(x => x.dto.id).indexOf(x) > -1);
    }

    get newUnSelectedItems() {
        const items = this.selectedLocalityId.filter(x => this.selectedLocalityIds.indexOf(x) == -1);

        return items.filter(x => this.allLocality.map(x => x.dto.id).indexOf(x) > -1);
    }

    get equals() {
        return this.processing || this.newSelectedItems.length == 0 && this.newUnSelectedItems.length == 0
    }

    get selectedLocalityIds(): string[] {
        return this.value.map(x => x.selectedChildren).reduce(function (x, y) {
            return x.concat(y);
        }, []).concat(this.value.filter(x => x.selected)).map(x => x.dto.id)
    }

    get selectedExpandedIds(): string[] {
        return this.value.map(x => x.expandedChildren).reduce(function (x, y) {
            return x.concat(y);
        }, []).concat(this.value.filter(x => x.expand)).map(x => x.dto.id)
    }

    get allLocality(): LocalityItemStore[] {
        return this.value.map(x => x.allChildren).reduce(function (x, y) {
            return x.concat(y);
        }, []).concat(this.value)
    }

    reset() {
        this.allLocality.forEach(x => x._selected = this.selectedLocalityId.indexOf(x.dto.id) > -1)
    }

    async beforeLoad(): Promise<void> {
        const config = getApiConfiguration();
        config.headers!['Jurisdiction'] = this.store.jurisdiction.id!;

        const api: LocalitiesApi = new LocalitiesApi(config);

        const filters = {
            queryType: QueryType.Tree,
            parentId: undefined,
            order: "ascending",
            page: 1,
            size: 100,
        } as GetTypeOrgsRequest;
        await this.loadSelected()

        this.emptyId = (await api.getLocalities(filters)).map(x => x.id)[0] ?? null;
        return super.beforeLoad();
    }

    async loadSelected(): Promise<void> {
        const filters = {
            page: 1,
            boundId: this.store.id,
            size: 1000,
            jurisdictionId: this.store.jurisdiction.id
        } as GetBoundLocalitiesRequest;
        const api: ClientLocalitiesApi = new ClientLocalitiesApi(getApiConfiguration());
        this.initLocality = (await api.getBoundLocalities(filters)).map(x => x.locality);
        this.selectedLocalityId = this.initLocality.map(x => x.id);
    }

    async request(): Promise<LocalityItemStore[]> {
        let result: LocalityDto[] = [];

        if (this.initLocality.length > 0 && !this.search.value) {
            result = this.initLocality;
        } else {
            const config = getApiConfiguration();
            config.headers!['Jurisdiction'] = this.store.jurisdiction.id!;

            const filters = {
                queryType: !(this.search.value ?? undefined) ? QueryType.Tree : undefined,
                search: this.search.value ?? undefined,
                parentId: undefined,
                order: "ascending",
                page: 1,
                size: 100,
                orderBy: 'nameRus'
            } as GetLocalitiesRequest;

            const api: LocalitiesApi = new LocalitiesApi(config);
            result = await api.getLocalities(filters);
        }

        const tree: LocalityDto[] = [];

        for (let i = 0; i < result.length; i++) {
            let element = result[i]
            let hasElement = tree.filter(x => x.id == element.id)[0]
            if (!hasElement) {
                tree.push(element)
                let hasParent = !tree.filter(x => x.id == element.parentId)[0];
                while (hasParent) {
                    element = element!.parent!;
                    tree.push(element)
                    const a = element?.parentId != element.id
                    hasParent = a && !tree.filter(x => x!.id == element?.parentId)[0];
                }
            }
        }

        const nest = (items: LocalityDto[], parent?: LocalityItemStore, link = 'parentId'): LocalityItemStore[] => {
            return items
                .filter((item: any) => item[link] === parent?.dto.id && item.id != item[link])
                .map(item => {
                    const a = new LocalityItemStore(
                        this,
                        item,
                        [],
                        parent,
                        this.initLocality.map(x => x.id).indexOf(item.id) > -1,
                        !!this.search.value)
                    a.value = nest(items.filter(x => x.id != item.id), a)
                        .sort((a, b) => sort(a.dto, b.dto, 'nameRus'));

                    return a;
                });
        }

        const items = tree.filter(x => x.id == this.emptyId).map(x => {
            const a = new LocalityItemStore(
                this,
                x,
                [],
                undefined,
                this.initLocality.map(x => x.id).indexOf(this.emptyId!) > -1,
                !!this.search.value)

            a.value = nest(tree, a).sort((a, b) => sort(a.dto, b.dto, 'nameRus'))

            return a;
        })

        const firstTreeItem = items[0];

        if (firstTreeItem && firstTreeItem.dto.id == firstTreeItem.dto.parentId) {
            firstTreeItem.expand = true;
        }

        return items;
    }

    async save(): Promise<void> {
        this.processing = true;
        try {
            if (this.newUnSelectedItems.length > 0) {
                await new ClientLocalitiesApi(getApiConfiguration()).removeBoundLocalities({
                    boundId: this._dto.id,
                    requestBody: this.newUnSelectedItems
                })
            }

            if (this.newSelectedItems.length > 0) {
                let result = await new ClientLocalitiesApi(getApiConfiguration()).setBoundLocalities({
                    boundId: this._dto.id,
                    requestBody: this.newSelectedItems
                })
            }
            await this.beforeLoad()
        } catch (e) {
        }
        this.processing = false;
    }
}

