import {makeAutoObservable} from "mobx";
import {ClinicCategoryTableStore} from "./ClinicCategoryTableStore";
import {PostTableStore} from "./PostTableStore";
import {SpecialityTableStore} from "./SpecialityTableStore";
import {TypeOrgTableStore} from "./TypeOrgTableStore";
import {OrganizationTableStore} from "./OrganizationTableStore";
import {PersonTableStore} from "./PersonTableStore";
import {RecordTypeTableStore} from "./RecordTypeTableStore";
import {LocalityTableStore} from "./LocalityTableStore";
import {BrickTableStore} from "./BrickTableStore";
import {ClientFragment} from "../../../../../gql/graphql";
import {OrganizationStatusTableStore} from "./OrganizationStatusTableStore";
import {PersonStatusTableStore} from "./PersonStatusTableStore";
import {TypeLocalityTableStore} from "./TypeLocalityTableStore";
import {ClientStore} from "../../client/ClientStore";

export class MappingsStore {
    readonly _store: ClientStore;
    private _dto: ClientFragment;
    readonly id: string;

    clientCategoryStore: ClinicCategoryTableStore;
    organizationStore: OrganizationTableStore;
    personStore: PersonTableStore;
    postStore: PostTableStore;
    recordTypeStore: RecordTypeTableStore;
    specialityStore: SpecialityTableStore;
    typeOrgStore: TypeOrgTableStore;
    localityStore: LocalityTableStore;
    brickStore: BrickTableStore;
    organizationStatusTableStore: OrganizationStatusTableStore;
    personStatusTableStore: PersonStatusTableStore;
    typeLocalityTableStore: TypeLocalityTableStore;

    constructor(store: ClientStore, dto: ClientFragment) {
        this._store = store;
        this._dto = dto;
        this.id = dto.id;

        this.clientCategoryStore = new ClinicCategoryTableStore(this, dto)
        this.organizationStore = new OrganizationTableStore(this, dto)
        this.personStore = new PersonTableStore(this, dto)
        this.postStore = new PostTableStore(this, dto)
        this.recordTypeStore = new RecordTypeTableStore(this, dto)
        this.specialityStore = new SpecialityTableStore(this, dto)
        this.typeOrgStore = new TypeOrgTableStore(this, dto)
        this.localityStore = new LocalityTableStore(this, dto)
        this.brickStore = new BrickTableStore(this, dto)
        this.organizationStatusTableStore = new OrganizationStatusTableStore(this, dto)
        this.personStatusTableStore = new PersonStatusTableStore(this, dto)
        this.typeLocalityTableStore = new TypeLocalityTableStore(this, dto)

        makeAutoObservable(this);
    }

    async updateState() {
        this.clientCategoryStore.reload()
        this.organizationStore.reload()
        this.personStore.reload()
        this.postStore.reload()
        this.recordTypeStore.reload()
        this.specialityStore.reload()
        this.typeOrgStore.reload()
        this.localityStore.reload()
        this.brickStore.reload()
        this.organizationStatusTableStore.reload()
        this.personStatusTableStore.reload()
        this.typeLocalityTableStore.reload()
    }
}

