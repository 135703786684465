/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    DcrGroupPermissionDto,
    DcrGroupPermissionDtoFromJSON,
    DcrGroupPermissionDtoFromJSONTyped,
    DcrGroupPermissionDtoToJSON,
} from './DcrGroupPermissionDto';

/**
 * 
 * @export
 * @interface DcrGroupPermissionDtoPagedResponse
 */
export interface DcrGroupPermissionDtoPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof DcrGroupPermissionDtoPagedResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<DcrGroupPermissionDto>}
     * @memberof DcrGroupPermissionDtoPagedResponse
     */
    items?: Array<DcrGroupPermissionDto> | null;
}

export function DcrGroupPermissionDtoPagedResponseFromJSON(json: any): DcrGroupPermissionDtoPagedResponse {
    return DcrGroupPermissionDtoPagedResponseFromJSONTyped(json, false);
}

export function DcrGroupPermissionDtoPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DcrGroupPermissionDtoPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(DcrGroupPermissionDtoFromJSON)),
    };
}

export function DcrGroupPermissionDtoPagedResponseToJSON(value?: DcrGroupPermissionDtoPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(DcrGroupPermissionDtoToJSON)),
    };
}

