import {getApiConfiguration} from "../../../../../../core";
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import {UserDto, UsersApi as MainUserApi} from "../../../../../../services/management";
import i18n from "i18next";
import {makeAutoObservable} from "mobx";
import {UserItemStore} from "../UserItemStore";
import {DefaultFormStore} from "../../../../../../components/shared/DefaultFormStore";
import {DcrGroupListDto, GroupApi, UserApi} from "../../../../../../services/dcr";
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";

export class UserParametersTabStore {
    processing: boolean = false;
    store: UserItemStore;
    form: DefaultFormStore<{
        login: DefaultValueStore<string>;
        name: DefaultValueStore<string>;
        password: DefaultValueStore<string>;
        confirmPassword: DefaultValueStore<string>;
        dcrGroup: DefaultSelectStore<DcrGroupListDto>
    }>;
    _isShowPassword: boolean = false;
    _isShowConfirmPassword: boolean = false;

    constructor(store: UserItemStore) {
        this.store = store;
        this.form = new DefaultFormStore({
            login: new DefaultValueStore<string>(this.store.dto.username!),
            name: new DefaultValueStore<string>(this.store.dto.name!),
            password: new DefaultValueStore<string>(""),
            confirmPassword: new DefaultValueStore<string>("", (e) =>
                (this.form.fields.password.value ?? "") !== (e ?? "") ? i18n.t('errors.password') : ""
            ),
            dcrGroup: new DefaultSelectStore<DcrGroupListDto>(
                null,
                (e) => new GroupApi(getApiConfiguration() as any)
                    .getGroups({userId: this.store.dto.id}).then(x => x.items!))
        }, this.save.bind(this))

        this.form.fields.dcrGroup.defaultFirstValue = true;
        this.form.afterSubmit = async () => await this.store._store.pull();
        this.form.fields.name.required = true;
        this.form.fields.login.required = true;
        this.form.fields.password.onChanged.push((e) => this.form.fields.confirmPassword.validate())
        makeAutoObservable(this);
    }

    update(dto: UserDto) {
        this.form.fields.login.update(dto.username!);
        this.form.fields.name.update(dto.name!);
    }

    get isShowPassword (){
        return this._isShowPassword
    }

    get isShowConfirmPassword (){
        return this._isShowConfirmPassword
    }

    set isShowPassword(x: boolean){
        this._isShowPassword = x
    }

    set isShowConfirmPassword(x: boolean){
        this._isShowConfirmPassword = x
    }

    async save(): Promise<void> {
        if (this.form.fields.dcrGroup.value) {
            new UserApi(getApiConfiguration({
                successTooltip: false
            })).setUserDefaultDcrGroup({
                setUserDefaultDcrGroupDto: {
                    userId: this.store.dto.id,
                    groupId: this.form.fields.dcrGroup.value.id
                }
            }).then()
        }

        const api: MainUserApi = new MainUserApi(getApiConfiguration());
        const result = await api.updateUser({
            id: this.store.dto.id,
            setUserDto: {
                username: this.form.model.login,
                password: this.form.model.password,
                name: this.form.model.name,
            }
        });

        const updateUserResult = await api.getUserById({id: result.id});
        this.update(updateUserResult);
    };
}
