/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface ClientS3Settings
 */
export interface ClientS3Settings {
    /**
     * 
     * @type {string}
     * @memberof ClientS3Settings
     */
    requestBucket?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientS3Settings
     */
    responseBucket?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientS3Settings
     */
    organizationRequestFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientS3Settings
     */
    personRequestFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientS3Settings
     */
    responseFileName?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ClientS3Settings
     */
    settings?: { [key: string]: any; } | null;
}

export function ClientS3SettingsFromJSON(json: any): ClientS3Settings {
    return ClientS3SettingsFromJSONTyped(json, false);
}

export function ClientS3SettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientS3Settings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestBucket': !exists(json, 'requestBucket') ? undefined : json['requestBucket'],
        'responseBucket': !exists(json, 'responseBucket') ? undefined : json['responseBucket'],
        'organizationRequestFileName': !exists(json, 'organizationRequestFileName') ? undefined : json['organizationRequestFileName'],
        'personRequestFileName': !exists(json, 'personRequestFileName') ? undefined : json['personRequestFileName'],
        'responseFileName': !exists(json, 'responseFileName') ? undefined : json['responseFileName'],
        'settings': !exists(json, 'settings') ? undefined : json['settings'],
    };
}

export function ClientS3SettingsToJSON(value?: ClientS3Settings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestBucket': value.requestBucket,
        'responseBucket': value.responseBucket,
        'organizationRequestFileName': value.organizationRequestFileName,
        'personRequestFileName': value.personRequestFileName,
        'responseFileName': value.responseFileName,
        'settings': value.settings,
    };
}

